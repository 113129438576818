@charset "UTF-8";
@import url(http://fonts.googleapis.com/css?family=Merriweather:400,400italic,700,700italic|Open+Sans:400italic,600italic,700italic,400,600,700);
/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Normalizes default `font-family` and sets `font-size` here to support `rem` units
 * 2. Prevents iOS text size adjust after orientation change, without disabling user zoom
 * 3. Style
 */
html {
  /* 1 */
  font: normal 1rem / 1.5 "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* 3 */
  background: #fff;
  color: #444; }

/*
 * Removes default margin.
 */
body {
  margin: 0; }

/* Links
 ========================================================================== */
/*
 * Remove the gray background color from active links in IE 10.
 */
a {
  background: transparent; }

/*
 * Improves readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/*
 * Style
 */
a,
.igt-link {
  color: #39a448;
  text-decoration: none;
  cursor: pointer; }

a:hover,
.igt-link:hover {
  color: #4ec25f;
  text-decoration: underline; }

/* Text-level semantics
 ========================================================================== */
/*
 * Addresses styling not present in Chrome, Safari, Opera and IE 8/9/10.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/*
 * Addresses style set to `bolder` in Firefox
 */
b,
strong {
  font-weight: bold; }

/*
 * 1. Address odd `em`-unit font size rendering in all browsers.
 * 2. Consolas has a better baseline in running text compared to `Courier`
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-size: 14px;
  /* 2 */
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* 3 */
  color: #444;
  white-space: normal;
  background: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  display: block;
  border-radius: 5px; }

/*
 * Emphasize
 */
em {
  color: #444; }

/*
 * Insert
 */
ins {
  background: #ffa;
  color: #444;
  text-decoration: none; }

/*
 * Mark
 * Note: Addresses styling not present in IE 8/9.
 */
mark {
  background: #ffa;
  color: #444; }

/*
 * Quote
 */
q {
  font-style: italic; }

/*
 * Addresses inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Images
 ========================================================================== */
/*
 * 1. Responsiveness: Sets a maximum width relative to the parent and auto scales the height
 * 2. Corrects `max-width` behavior if padding and border are used
 * 3. Remove border when inside `a` element in IE 8/9/10.
 * 4. Remove the gap between images and the bottom of their containers
 */
img {
  /* 1 */
  max-width: 100%;
  height: auto;
  /* 2 */
  box-sizing: border-box;
  /* 3 */
  border: 0;
  /* 4 */
  vertical-align: middle; }

/*
 * Preserve original image dimensions
 */
.igt-img-preserve,
.igt-img-preserve img {
  max-width: none; }

/*
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Block elements
 ========================================================================== */
/*
 * Reset margin
 */
blockquote,
figure {
  margin: 0; }

/*
 * Margins
 */
p,
ul,
ol,
dl,
blockquote,
pre,
address,
fieldset,
figure {
  margin: 0 0 15px 0; }

* + p,
* + ul,
* + ol,
* + dl,
* + blockquote,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 15px; }

/* Headings
 ========================================================================== */
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 15px 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #3c474b;
  text-transform: none; }

/*
 * Margins
 */
* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6 {
  margin-top: 25px; }

/*
 * Sizes
 */
h1, .igt-h1 {
  font-size: 3.583rem;
  line-height: 1.5; }

h2, .igt-h2 {
  font-size: 2.986rem;
  line-height: 1.5; }

h3, .igt-h3 {
  font-size: 2.074rem;
  line-height: 1.5; }

h4, .igt-h4 {
  font-size: 1.728rem;
  line-height: 1.5; }

h5, .igt-h5 {
  font-size: 1.44rem;
  line-height: 1.5; }

h6, .igt-h6 {
  font-size: 1.2rem;
  line-height: 1.5; }

/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 30px; }

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0; }

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Address differences between Firefox and other browsers.
 * 2. Style
 */
hr {
  /* 1 */
  box-sizing: content-box;
  height: 0;
  /* 2 */
  margin: 15px 0;
  border: 0;
  border-top: 2px solid #eee; }

/* Address
 ========================================================================== */
address {
  font-style: normal; }

/* Blockquotes
 ========================================================================== */
blockquote {
  padding-left: 15px;
  border-left: 5px solid #ddd;
  font-size: 16px;
  line-height: 22px;
  font-style: italic; }

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  padding: 10px;
  background: #f5f5f5;
  font: 12px / 18px "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #444;
  -moz-tab-size: 4;
  -o-tab-size: 4;
     tab-size: 4;
  /* 1 */
  overflow: auto; }

/* Selection pseudo-element
 ========================================================================== */
::-moz-selection {
  background: #39f;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #39f;
  color: #fff;
  text-shadow: none; }

/* HTML5 elements
 ========================================================================== */
/*
 * Corrects `block` display not defined in IE 8/9.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block; }

/*
 * Addresses inconsistent vertical alignment of `progress` in Chrome, Firefox and Opera.
 */
progress {
  vertical-align: baseline; }

/*
 * Prevent displaying `audio` without controls in Chrome, Safari and Opera
 */
audio:not([controls]) {
  display: none; }

/*
 * Address `[hidden]` styling not present in IE 8/9.
 * Hide the `template` element in IE and Safari
 */
[hidden],
template {
  display: none; }

/* Iframe
 ========================================================================== */
iframe {
  border: 0; }

/* Fix viewport for IE10 snap mode
 ========================================================================== */
@media screen and (max-width: 400px) {
  @-ms-viewport {
    width: device-width; } }

/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Makes grid more robust so that it can be used with other block elements like lists
 */
.igt-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * DEPRECATED
 * Micro clearfix
 * Can't use `table` because it creates a 1px gap when it becomes a flex item, only in Webkit
 */
.igt-grid:before,
.igt-grid:after {
  content: "";
  display: block;
  overflow: hidden; }

.igt-grid:after {
  clear: both; }

/*
 * Grid cell
 * 1. Space is allocated solely based on content dimensions
 * 2. Makes grid more robust so that it can be used with other block elements
 * 3. DEPRECATED Using `float` to support IE9
 */
.igt-grid > * {
  /* 1 */
  -ms-flex: none;
  -webkit-flex: none;
  -webkit-box-flex: 0;
          flex: none;
  /* 2 */
  margin: 0;
  /* 3 */
  float: left; }

/*
 * Remove margin from the last-child
 */
.igt-grid > * > :last-child {
  margin-bottom: 0; }

/* Grid gutter
 ========================================================================== */
/*
 * Default gutter
 */
/* Horizontal */
.igt-grid {
  margin-left: -25px; }

.igt-grid > * {
  padding-left: 25px; }

/* Vertical */
.igt-grid + .igt-grid,
.igt-grid-margin,
.igt-grid > * > .igt-panel + .igt-panel {
  margin-top: 25px; }

/* Large screen and bigger */
@media (min-width: 1220px) {
  /* Horizontal */
  .igt-grid {
    margin-left: -35px; }
  .igt-grid > * {
    padding-left: 35px; }
  /* Vertical */
  .igt-grid + .igt-grid,
  .igt-grid-margin,
  .igt-grid > * > .igt-panel + .igt-panel {
    margin-top: 35px; } }

/*
 * Collapse gutter
 */
/* Horizontal */
.igt-grid-collapse {
  margin-left: 0; }

.igt-grid-collapse > * {
  padding-left: 0; }

/* Vertical */
.igt-grid-collapse + .igt-grid-collapse,
.igt-grid-collapse > .igt-grid-margin,
.igt-grid-collapse > * > .igt-panel + .igt-panel {
  margin-top: 0; }

/*
 * Small gutter
 */
/* Horizontal */
.igt-grid-small {
  margin-left: -10px; }

.igt-grid-small > * {
  padding-left: 10px; }

/* Vertical */
.igt-grid-small + .igt-grid-small,
.igt-grid-small > .igt-grid-margin,
.igt-grid-small > * > .igt-panel + .igt-panel {
  margin-top: 10px; }

/*
 * Medium gutter
 */
/* Horizontal */
.igt-grid-medium {
  margin-left: -25px; }

.igt-grid-medium > * {
  padding-left: 25px; }

/* Vertical */
.igt-grid-medium + .igt-grid-medium,
.igt-grid-medium > .igt-grid-margin,
.igt-grid-medium > * > .igt-panel + .igt-panel {
  margin-top: 25px; }

/* Modifier: `igt-grid-divider`
 ========================================================================== */
/*
 * Horizontal divider
 * Only works with the default gutter. Does not work with gutter collapse, small or large.
 * Does not work with `igt-push-*`, `igt-pull-*` and not if the columns float into the next row.
 */
.igt-grid-divider:not(:empty) {
  margin-left: -25px;
  margin-right: -25px; }

.igt-grid-divider > * {
  padding-left: 25px;
  padding-right: 25px; }

.igt-grid-divider > [class*='igt-width-1-']:not(.igt-width-1-1):nth-child(n+2),
.igt-grid-divider > [class*='igt-width-2-']:nth-child(n+2),
.igt-grid-divider > [class*='igt-width-3-']:nth-child(n+2),
.igt-grid-divider > [class*='igt-width-4-']:nth-child(n+2),
.igt-grid-divider > [class*='igt-width-5-']:nth-child(n+2),
.igt-grid-divider > [class*='igt-width-6-']:nth-child(n+2),
.igt-grid-divider > [class*='igt-width-7-']:nth-child(n+2),
.igt-grid-divider > [class*='igt-width-8-']:nth-child(n+2),
.igt-grid-divider > [class*='igt-width-9-']:nth-child(n+2) {
  border-left: 1px solid #ddd; }

/* Tablet and bigger */
@media (min-width: 768px) {
  .igt-grid-divider > [class*='igt-width-medium-']:not(.igt-width-medium-1-1):nth-child(n+2) {
    border-left: 1px solid #ddd; } }

/* Desktop and bigger */
@media (min-width: 960px) {
  .igt-grid-divider > [class*='igt-width-large-']:not(.igt-width-large-1-1):nth-child(n+2) {
    border-left: 1px solid #ddd; } }

/* Large screen and bigger */
@media (min-width: 1220px) {
  /*
     * Large gutter
     */
  .igt-grid-divider:not(:empty) {
    margin-left: -35px;
    margin-right: -35px; }
  .igt-grid-divider > * {
    padding-left: 35px;
    padding-right: 35px; }
  .igt-grid-divider:empty {
    margin-top: 35px;
    margin-bottom: 35px; } }

/*
 * Vertical divider
 */
.igt-grid-divider:empty {
  margin-top: 25px;
  margin-bottom: 25px;
  border-top: 1px solid #ddd; }

/* Match panels in grids
 ========================================================================== */
.igt-grid-match > * {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex; }

/*
 * 1. Behave like a block element
 */
.igt-grid-match > * > * {
  /* 1 */
  -ms-flex: none;
  -webkit-flex: none;
  -webkit-box-flex: 0;
          flex: none;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%; }

/* Even grid cell widths
 ========================================================================== */
[class*='igt-grid-width'] > * {
  box-sizing: border-box;
  width: 100%; }

.igt-grid-width-1-2 > * {
  width: 50%; }

.igt-grid-width-1-3 > * {
  width: 33.333%; }

.igt-grid-width-1-4 > * {
  width: 25%; }

.igt-grid-width-1-5 > * {
  width: 20%; }

.igt-grid-width-1-6 > * {
  width: 16.666%; }

.igt-grid-width-1-10 > * {
  width: 10%; }

/* Phone landscape and bigger */
@media (min-width: 480px) {
  .igt-grid-width-small-1-2 > * {
    width: 50%; }
  .igt-grid-width-small-1-3 > * {
    width: 33.333%; }
  .igt-grid-width-small-1-4 > * {
    width: 25%; }
  .igt-grid-width-small-1-5 > * {
    width: 20%; }
  .igt-grid-width-small-1-6 > * {
    width: 16.666%; }
  .igt-grid-width-small-1-10 > * {
    width: 10%; } }

/* Tablet and bigger */
@media (min-width: 768px) {
  .igt-grid-width-medium-1-2 > * {
    width: 50%; }
  .igt-grid-width-medium-1-3 > * {
    width: 33.333%; }
  .igt-grid-width-medium-1-4 > * {
    width: 25%; }
  .igt-grid-width-medium-1-5 > * {
    width: 20%; }
  .igt-grid-width-medium-1-6 > * {
    width: 16.666%; }
  .igt-grid-width-medium-1-10 > * {
    width: 10%; } }

/* Desktop and bigger */
@media (min-width: 960px) {
  .igt-grid-width-large-1-2 > * {
    width: 50%; }
  .igt-grid-width-large-1-3 > * {
    width: 33.333%; }
  .igt-grid-width-large-1-4 > * {
    width: 25%; }
  .igt-grid-width-large-1-5 > * {
    width: 20%; }
  .igt-grid-width-large-1-6 > * {
    width: 16.666%; }
  .igt-grid-width-large-1-10 > * {
    width: 10%; } }

/* Large screen and bigger */
@media (min-width: 1220px) {
  .igt-grid-width-xlarge-1-2 > * {
    width: 50%; }
  .igt-grid-width-xlarge-1-3 > * {
    width: 33.333%; }
  .igt-grid-width-xlarge-1-4 > * {
    width: 25%; }
  .igt-grid-width-xlarge-1-5 > * {
    width: 20%; }
  .igt-grid-width-xlarge-1-6 > * {
    width: 16.666%; }
  .igt-grid-width-xlarge-1-10 > * {
    width: 10%; } }

/* Sub-objects: `igt-width-*`
 ========================================================================== */
[class*='igt-width'] {
  box-sizing: border-box;
  width: 100%; }

/*
 * Widths
 */
/* Whole */
.igt-width-1-1 {
  width: 100%; }

/* Halves */
.igt-width-1-2,
.igt-width-2-4,
.igt-width-3-6,
.igt-width-5-10 {
  width: 50%; }

/* Thirds */
.igt-width-1-3,
.igt-width-2-6 {
  width: 33.333%; }

.igt-width-2-3,
.igt-width-4-6 {
  width: 66.666%; }

/* Quarters */
.igt-width-1-4 {
  width: 25%; }

.igt-width-3-4 {
  width: 75%; }

/* Fifths */
.igt-width-1-5,
.igt-width-2-10 {
  width: 20%; }

.igt-width-2-5,
.igt-width-4-10 {
  width: 40%; }

.igt-width-3-5,
.igt-width-6-10 {
  width: 60%; }

.igt-width-4-5,
.igt-width-8-10 {
  width: 80%; }

/* Sixths */
.igt-width-1-6 {
  width: 16.666%; }

.igt-width-5-6 {
  width: 83.333%; }

/* Tenths */
.igt-width-1-10 {
  width: 10%; }

.igt-width-3-10 {
  width: 30%; }

.igt-width-7-10 {
  width: 70%; }

.igt-width-9-10 {
  width: 90%; }

/* Phone landscape and bigger */
@media (min-width: 480px) {
  /* Whole */
  .igt-width-small-1-1 {
    width: 100%; }
  /* Halves */
  .igt-width-small-1-2,
  .igt-width-small-2-4,
  .igt-width-small-3-6,
  .igt-width-small-5-10 {
    width: 50%; }
  /* Thirds */
  .igt-width-small-1-3,
  .igt-width-small-2-6 {
    width: 33.333%; }
  .igt-width-small-2-3,
  .igt-width-small-4-6 {
    width: 66.666%; }
  /* Quarters */
  .igt-width-small-1-4 {
    width: 25%; }
  .igt-width-small-3-4 {
    width: 75%; }
  /* Fifths */
  .igt-width-small-1-5,
  .igt-width-small-2-10 {
    width: 20%; }
  .igt-width-small-2-5,
  .igt-width-small-4-10 {
    width: 40%; }
  .igt-width-small-3-5,
  .igt-width-small-6-10 {
    width: 60%; }
  .igt-width-small-4-5,
  .igt-width-small-8-10 {
    width: 80%; }
  /* Sixths */
  .igt-width-small-1-6 {
    width: 16.666%; }
  .igt-width-small-5-6 {
    width: 83.333%; }
  /* Tenths */
  .igt-width-small-1-10 {
    width: 10%; }
  .igt-width-small-3-10 {
    width: 30%; }
  .igt-width-small-7-10 {
    width: 70%; }
  .igt-width-small-9-10 {
    width: 90%; } }

/* Tablet and bigger */
@media (min-width: 768px) {
  /* Whole */
  .igt-width-medium-1-1 {
    width: 100%; }
  /* Halves */
  .igt-width-medium-1-2,
  .igt-width-medium-2-4,
  .igt-width-medium-3-6,
  .igt-width-medium-5-10 {
    width: 50%; }
  /* Thirds */
  .igt-width-medium-1-3,
  .igt-width-medium-2-6 {
    width: 33.333%; }
  .igt-width-medium-2-3,
  .igt-width-medium-4-6 {
    width: 66.666%; }
  /* Quarters */
  .igt-width-medium-1-4 {
    width: 25%; }
  .igt-width-medium-3-4 {
    width: 75%; }
  /* Fifths */
  .igt-width-medium-1-5,
  .igt-width-medium-2-10 {
    width: 20%; }
  .igt-width-medium-2-5,
  .igt-width-medium-4-10 {
    width: 40%; }
  .igt-width-medium-3-5,
  .igt-width-medium-6-10 {
    width: 60%; }
  .igt-width-medium-4-5,
  .igt-width-medium-8-10 {
    width: 80%; }
  /* Sixths */
  .igt-width-medium-1-6 {
    width: 16.666%; }
  .igt-width-medium-5-6 {
    width: 83.333%; }
  /* Tenths */
  .igt-width-medium-1-10 {
    width: 10%; }
  .igt-width-medium-3-10 {
    width: 30%; }
  .igt-width-medium-7-10 {
    width: 70%; }
  .igt-width-medium-9-10 {
    width: 90%; } }

/* Desktop and bigger */
@media (min-width: 960px) {
  /* Whole */
  .igt-width-large-1-1 {
    width: 100%; }
  /* Halves */
  .igt-width-large-1-2,
  .igt-width-large-2-4,
  .igt-width-large-3-6,
  .igt-width-large-5-10 {
    width: 50%; }
  /* Thirds */
  .igt-width-large-1-3,
  .igt-width-large-2-6 {
    width: 33.333%; }
  .igt-width-large-2-3,
  .igt-width-large-4-6 {
    width: 66.666%; }
  /* Quarters */
  .igt-width-large-1-4 {
    width: 25%; }
  .igt-width-large-3-4 {
    width: 75%; }
  /* Fifths */
  .igt-width-large-1-5,
  .igt-width-large-2-10 {
    width: 20%; }
  .igt-width-large-2-5,
  .igt-width-large-4-10 {
    width: 40%; }
  .igt-width-large-3-5,
  .igt-width-large-6-10 {
    width: 60%; }
  .igt-width-large-4-5,
  .igt-width-large-8-10 {
    width: 80%; }
  /* Sixths */
  .igt-width-large-1-6 {
    width: 16.666%; }
  .igt-width-large-5-6 {
    width: 83.333%; }
  /* Tenths */
  .igt-width-large-1-10 {
    width: 10%; }
  .igt-width-large-3-10 {
    width: 30%; }
  .igt-width-large-7-10 {
    width: 70%; }
  .igt-width-large-9-10 {
    width: 90%; } }

/* Sub-object: `igt-push-*` and `igt-pull-*`
 ========================================================================== */
/*
 * Source ordering
 * Works only with `igt-width-medium-*`
 */
/* Tablet and bigger */
@media (min-width: 768px) {
  [class*='igt-push-'],
  [class*='igt-pull-'] {
    position: relative; }
  /*
     * Push
     */
  /* Halves */
  .igt-push-1-2,
  .igt-push-2-4,
  .igt-push-3-6,
  .igt-push-5-10 {
    left: 50%; }
  /* Thirds */
  .igt-push-1-3,
  .igt-push-2-6 {
    left: 33.333%; }
  .igt-push-2-3,
  .igt-push-4-6 {
    left: 66.666%; }
  /* Quarters */
  .igt-push-1-4 {
    left: 25%; }
  .igt-push-3-4 {
    left: 75%; }
  /* Fifths */
  .igt-push-1-5,
  .igt-push-2-10 {
    left: 20%; }
  .igt-push-2-5,
  .igt-push-4-10 {
    left: 40%; }
  .igt-push-3-5,
  .igt-push-6-10 {
    left: 60%; }
  .igt-push-4-5,
  .igt-push-8-10 {
    left: 80%; }
  /* Sixths */
  .igt-push-1-6 {
    left: 16.666%; }
  .igt-push-5-6 {
    left: 83.333%; }
  /* Tenths */
  .igt-push-1-10 {
    left: 10%; }
  .igt-push-3-10 {
    left: 30%; }
  .igt-push-7-10 {
    left: 70%; }
  .igt-push-9-10 {
    left: 90%; }
  /*
     * Pull
     */
  /* Halves */
  .igt-pull-1-2,
  .igt-pull-2-4,
  .igt-pull-3-6,
  .igt-pull-5-10 {
    left: -50%; }
  /* Thirds */
  .igt-pull-1-3,
  .igt-pull-2-6 {
    left: -33.333%; }
  .igt-pull-2-3,
  .igt-pull-4-6 {
    left: -66.666%; }
  /* Quarters */
  .igt-pull-1-4 {
    left: -25%; }
  .igt-pull-3-4 {
    left: -75%; }
  /* Fifths */
  .igt-pull-1-5,
  .igt-pull-2-10 {
    left: -20%; }
  .igt-pull-2-5,
  .igt-pull-4-10 {
    left: -40%; }
  .igt-pull-3-5,
  .igt-pull-6-10 {
    left: -60%; }
  .igt-pull-4-5,
  .igt-pull-8-10 {
    left: -80%; }
  /* Sixths */
  .igt-pull-1-6 {
    left: -16.666%; }
  .igt-pull-5-6 {
    left: -83.333%; }
  /* Tenths */
  .igt-pull-1-10 {
    left: -10%; }
  .igt-pull-3-10 {
    left: -30%; }
  .igt-pull-7-10 {
    left: -70%; }
  .igt-pull-9-10 {
    left: -90%; } }

/* ========================================================================
   Component: Panel
 ========================================================================== */
/*
 * 1. Needed for `a` elements
 * 2. Create position context for badges
 */
.igt-panel {
  /* 1 */
  display: block;
  /* 2 */
  position: relative; }

/*
 * Allow panels to be anchors
 */
.igt-panel,
.igt-panel:hover {
  text-decoration: none; }

/*
 * Micro clearfix to make panels more robust
 */
.igt-panel:before,
.igt-panel:after {
  content: "";
  display: table; }

.igt-panel:after {
  clear: both; }

/*
 * Remove margin from the last-child if not `igt-widget-title`
 */
.igt-panel > :not(.igt-panel-title):last-child {
  margin-bottom: 0; }

/* Sub-object: `igt-panel-title`
 ========================================================================== */
.igt-panel-title {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  text-transform: none;
  color: #444; }

/* Sub-object: `igt-panel-badge`
 ========================================================================== */
.igt-panel-badge {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1; }

/* Sub-object: `igt-panel-teaser`
 ========================================================================== */
.igt-panel-teaser {
  margin-bottom: 15px; }

/* Modifier: `igt-panel-box`
 ========================================================================== */
.igt-panel-box {
  padding: 15px;
  background: #f5f5f5;
  color: #444;
  border-radius: 5px; }

.igt-panel-box-hover:hover {
  color: #444; }

.igt-panel-box .igt-panel-title {
  color: #444; }

.igt-panel-box .igt-panel-badge {
  top: 10px;
  right: 10px; }

.igt-panel-box .igt-panel-teaser {
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px; }

/*
 * Nav in panel
 */
.igt-panel-box > .igt-nav-side {
  margin: 0 -15px; }

/*
 * Sub-modifier: `igt-panel-box-primary`
 */
.igt-panel-box-primary {
  background-color: #fff;
  color: #444;
  border-color: #eee;
  border-style: solid;
  border-width: 0.25rem; }

.igt-panel-box-primary:hover,
.igt-panel-box-primary-hover:hover {
  color: #444;
  border-color: #39a448; }

.igt-panel-box-primary .igt-panel-title {
  color: #444; }

/*
 * Sub-modifier: `igt-panel-box-secondary`
 */
.igt-panel-box-secondary {
  background-color: #fff;
  color: #444;
  box-shadow: 0 2px 2px rgba(68, 68, 68, 0.5); }

.igt-panel-box-secondary-hover:hover {
  color: #444; }

.igt-panel-box-secondary .igt-panel-title {
  color: #444; }

/* Modifier: `igt-panel-hover`
 ========================================================================== */
.igt-panel-hover {
  padding: 15px;
  color: #444; }

.igt-panel-hover:hover {
  background: #f5f5f5;
  color: #444; }

.igt-panel-hover .igt-panel-badge {
  top: 10px;
  right: 10px; }

.igt-panel-hover .igt-panel-teaser {
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px; }

/* Modifier: `igt-panel-header`
 ========================================================================== */
.igt-panel-header .igt-panel-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  color: #444; }

/* Modifier: `igt-panel-space`
 ========================================================================== */
.igt-panel-space {
  padding: 30px; }

.igt-panel-space .igt-panel-badge {
  top: 30px;
  right: 30px; }

/* Modifier: `igt-panel-divider`
 ========================================================================== */
.igt-panel + .igt-panel-divider {
  margin-top: 50px !important; }

.igt-panel + .igt-panel-divider:before {
  content: "";
  display: block;
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  border-top: 1px solid #ddd; }

/* Large screen and bigger */
@media (min-width: 1220px) {
  .igt-panel + .igt-panel-divider {
    margin-top: 70px !important; }
  .igt-panel + .igt-panel-divider:before {
    top: -35px; } }

/* ========================================================================
   Component: Block
 ========================================================================== */
.igt-block {
  position: relative;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px; }

/* Phone landscape and bigger */
@media (min-width: 768px) {
  .igt-block {
    padding-top: 50px;
    padding-bottom: 50px; } }

/*
 * Micro clearfix to make blocks more robust
 */
.igt-block:before,
.igt-block:after {
  content: "";
  display: table; }

.igt-block:after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.igt-block > :last-child {
  margin-bottom: 0; }

/* Padding Modifier
 ========================================================================== */
/*
 * Large padding
 */
.igt-block-large {
  padding-top: 20px;
  padding-bottom: 20px; }

/* Tablets and bigger */
@media (min-width: 768px) {
  .igt-block-large {
    padding-top: 50px;
    padding-bottom: 50px; } }

/* Desktop and bigger */
@media (min-width: 960px) {
  .igt-block-large {
    padding-top: 100px;
    padding-bottom: 100px; } }

/* Color Modifier
 ========================================================================== */
/*
 * Default
 */
.igt-block-default {
  background: #fff; }

/*
 * Muted
 */
.igt-block-muted {
  background: #f6f6f7; }

/*
 * Primary
 */
.igt-block-primary {
  background: #39a448; }

/*
 * Secondary
 */
.igt-block-secondary {
  background: #222; }

/*
 * Adjust padding between equal colored blocks
 */
.igt-block-default + .igt-block-default,
.igt-block-muted + .igt-block-muted,
.igt-block-primary + .igt-block-primary,
.igt-block-secondary + .igt-block-secondary {
  padding-top: 0; }

/* ========================================================================
   Component: Article
 ========================================================================== */
/*
 * Micro clearfix to make articles more robust
 */
.igt-article:before,
.igt-article:after {
  content: "";
  display: table; }

.igt-article:after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.igt-article > :last-child {
  margin-bottom: 0; }

/*
 * Vertical gutter for articles
 */
.igt-article + .igt-article {
  margin-top: 25px; }

/* Sub-object `igt-article-title`
 ========================================================================== */
.igt-article-title {
  font-size: 2.074rem;
  line-height: 1.25;
  font-weight: 600;
  text-transform: none;
  letter-spacing: -2px;
  margin-bottom: 0; }

@media (min-width: 767px) {
  .igt-article-title {
    font-size: 2.986rem; } }

.igt-article-title a {
  color: inherit;
  text-decoration: none; }

/* Sub-object `igt-article-meta`
 ========================================================================== */
.igt-article-meta {
  font-size: 1rem;
  line-height: 1.5;
  color: #999; }

/* Sub-object `igt-article-lead`
 ========================================================================== */
.igt-article-lead {
  color: #929aa0;
  font-family: "Merriweather", Georgia, serif;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0; }

@media (min-width: 959px) {
  .igt-article-lead {
    max-width: 80%; }
  .section-intro-trips .igt-article-lead {
    max-width: 100%; } }

@media (min-width: 767px) {
  .igt-article-lead {
    font-size: 1.44rem; } }

/* Sub-object `igt-article-divider`
 ========================================================================== */
.igt-article-divider {
  margin-bottom: 25px;
  border-color: #eee; }

* + .igt-article-divider {
  margin-top: 25px; }

/* ========================================================================
   Component: Comment
 ========================================================================== */
/* Sub-object `igt-comment-header`
 ========================================================================== */
.igt-comment-header {
  margin-bottom: 15px; }

/*
 * Micro clearfix
 */
.igt-comment-header:before,
.igt-comment-header:after {
  content: "";
  display: table; }

.igt-comment-header:after {
  clear: both; }

/* Sub-object `igt-comment-avatar`
 ========================================================================== */
.igt-comment-avatar {
  margin: 0 auto 15px auto;
  clear: both; }

/* Sub-object `igt-comment-title`
 ========================================================================== */
.igt-comment-title {
  margin: 5px 0 0 0;
  font-size: 16px;
  line-height: 22px; }

/* Sub-object `igt-comment-meta`
 ========================================================================== */
.igt-comment-meta {
  margin: 2px 0 0 0;
  font-size: 11px;
  line-height: 16px;
  color: #999; }

/* Sub-object `igt-comment-body`
 ========================================================================== */
/*
 * Remove margin from the last-child
 */
.igt-comment-body > :last-child {
  margin-bottom: 0; }

/* Sub-object `igt-comment-list`
 ========================================================================== */
.igt-comment-list {
  padding: 0;
  list-style: none; }

.igt-comment-list .igt-comment + ul {
  margin: 15px 0 0 0;
  list-style: none; }

.igt-comment-list > li:nth-child(n+2),
.igt-comment-list .igt-comment + ul > li:nth-child(n+2) {
  margin-top: 15px; }

/* Tablet and bigger */
@media (min-width: 768px) {
  .igt-comment-list .igt-comment + ul {
    padding-left: 100px; } }

/* Modifier `igt-comment-primary`
 ========================================================================== */
/* ========================================================================
   Component: Cover
 ========================================================================== */
/*
 * Background image always covers and centers its element
 */
.igt-cover-background {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat; }

/*
 * Emulates image cover, works with video and image elements
 * 1. Parent container which clips resized object
 * 2. Resizes the object to always covers its container
 * 3. Reset the responsive image CSS
 * 4. Center object
 */
/* 1 */
.igt-cover {
  overflow: hidden; }

.igt-cover-object {
  /* 2 */
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  /* 3 */
  max-width: none;
  /* 4 */
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }

/*
 * To center iframes use `data-igt-cover` JavaScript
 */
[data-igt-cover] {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }

/* ========================================================================
   Component: Nav
 ========================================================================== */
.igt-nav,
.igt-nav ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * Items
 */
.igt-nav li > a {
  display: block;
  text-decoration: none; }

.igt-nav > li > a {
  padding: 5px 15px; }

/*
 * Nested items
 */
.igt-nav ul {
  padding-left: 15px; }

.igt-nav ul a {
  padding: 2px 0; }

/*
 * Item subtitle
 */
.igt-nav li > a > div {
  font-size: 12px;
  line-height: 18px; }

/* Sub-object: `igt-nav-header`
 ========================================================================== */
.igt-nav-header {
  padding: 5px 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  background: #eee; }

.igt-nav-header:not(:first-child) {
  margin-top: 15px; }

/* Sub-object: `igt-nav-divider`
 ========================================================================== */
.igt-nav-divider {
  margin: 9px 15px; }

/* Sub-object: `igt-nav-sub`
 ========================================================================== */
/*
 * `ul` needed for higher specificity to override padding
 */
ul.igt-nav-sub {
  padding: 5px 0 5px 15px; }

/* Modifier: `igt-nav-parent-icon`
 ========================================================================== */
.igt-nav-parent-icon > .igt-parent > a:after {
  content: "";
  width: 20px;
  margin-right: -10px;
  float: right;
  font-family: FontAwesome;
  text-align: center; }

.igt-nav-parent-icon > .igt-parent.igt-open > a:after {
  content: ""; }

/* Modifier `igt-nav-side`
 ========================================================================== */
/*
 * Items
 */
.igt-nav-side > li > a {
  color: #444; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.igt-nav-side > li > a:hover,
.igt-nav-side > li > a:focus {
  background: rgba(0, 0, 0, 0.05);
  color: #444;
  /* 2 */
  outline: none; }

/* Active */
.igt-nav-side > li.igt-active > a {
  background: #39a448;
  color: #fff; }

/*
 * Sub-object: `igt-nav-header`
 */
.igt-nav-side .igt-nav-header {
  color: #444; }

/*
 * Sub-object: `igt-nav-divider`
 */
.igt-nav-side .igt-nav-divider {
  border-top: 1px solid #ddd; }

/*
 * Nested items
 */
.igt-nav-side ul a {
  color: #07D; }

.igt-nav-side ul a:hover {
  color: #059; }

/* Modifier `igt-nav-dropdown`
 ========================================================================== */
/*
 * Items
 */
.igt-nav-dropdown > li > a {
  color: #444; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.igt-nav-dropdown > li > a:hover,
.igt-nav-dropdown > li > a:focus {
  background: #39a448;
  color: #fff;
  /* 2 */
  outline: none; }

/*
 * Sub-object: `igt-nav-header`
 */
.igt-nav-dropdown .igt-nav-header {
  color: #999; }

/*
 * Sub-object: `igt-nav-divider`
 */
.igt-nav-dropdown .igt-nav-divider {
  border-top: 1px solid #ddd; }

/*
 * Nested items
 */
.igt-nav-dropdown ul a {
  color: #07D; }

.igt-nav-dropdown ul a:hover {
  color: #059; }

/* Modifier `igt-nav-navbar`
 ========================================================================== */
/*
 * Items
 */
.igt-nav-navbar > li > a {
  color: #444; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.igt-nav-navbar > li > a:hover,
.igt-nav-navbar > li > a:focus {
  background: #39a448;
  color: #fff;
  /* 2 */
  outline: none; }

/*
 * Sub-object: `igt-nav-header`
 */
.igt-nav-navbar .igt-nav-header {
  color: #999; }

/*
 * Sub-object: `igt-nav-divider`
 */
.igt-nav-navbar .igt-nav-divider {
  border-top: 1px solid #ddd; }

/*
 * Nested items
 */
.igt-nav-navbar ul a {
  color: #07D; }

.igt-nav-navbar ul a:hover {
  color: #059; }

/* Modifier `igt-nav-offcanvas`
 ========================================================================== */
/*
 * Items
 */
.igt-nav-offcanvas > li > a {
  color: #444;
  padding: 10px 15px; }

/*
 * Hover
 * No hover on touch devices because it behaves buggy in fixed offcanvas
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.igt-nav-offcanvas > .igt-open > a,
html:not(.igt-touch) .igt-nav-offcanvas > li > a:hover,
html:not(.igt-touch) .igt-nav-offcanvas > li > a:focus {
  background: #fafafa;
  color: #444;
  /* 2 */
  outline: none; }

/*
 * Active
 * `html .igt-nav` needed for higher specificity to override hover
 */
html .igt-nav.igt-nav-offcanvas > li.igt-active > a {
  background: #39a448;
  color: #fff; }

/*
 * Sub-object: `igt-nav-header`
 */
.igt-nav-offcanvas .igt-nav-header {
  color: #777; }

/*
 * Sub-object: `igt-nav-divider`
 */
.igt-nav-offcanvas .igt-nav-divider {
  border-top: 1px solid #ddd; }

/*
 * Nested items
 * No hover on touch devices because it behaves buggy in fixed offcanvas
 */
.igt-nav-offcanvas ul a {
  color: #ccc; }

html:not(.igt-touch) .igt-nav-offcanvas ul a:hover {
  color: #fff; }

/* ========================================================================
   Component: Navbar
 ========================================================================== */
.igt-navbar {
  background: #fff;
  color: #444;
  height: 7rem; }

.igt-navbar-top {
  height: 4rem; }

.igt-navbar-bottom {
  height: 3rem;
  box-shadow: inset 0 1px 0 #f5f5f5; }

@media (max-width: 959px) {
  .igt-navbar {
    height: 3.5rem; }
  .igt-navbar-top {
    height: 3.5rem; }
  .igt-navbar-top .igt-navbar-flip,
  .igt-navbar-bottom {
    display: none !important; } }

.igt-navbar-trips.igt-navbar {
  height: 4rem; }

.igt-navbar-trips .igt-navbar-nav > li > a {
  height: 40px;
  line-height: 40px; }

/*
 * Micro clearfix
 */
.igt-navbar:before,
.igt-navbar:after {
  content: "";
  display: table; }

.igt-navbar:after {
  clear: both; }

/* Sub-object: `igt-navbar-nav`
 ========================================================================== */
.igt-navbar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left; }

/*
 * 1. Create position context for dropdowns
 */
.igt-navbar-nav > li {
  float: left;
  /* 1 */
  position: relative; }

/*
 * 1. Dimensions
 * 2. Style
 */
.igt-navbar-nav > li > a {
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  /* 1 */
  height: 40px;
  padding: 0 15px !important;
  line-height: 40px;
  /* 2 */
  color: #444;
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal; }

/* Appear not as link */
.igt-navbar-nav > li > a[href='#'] {
  cursor: text; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Also apply if dropdown is opened
 * 3. Remove default focus style
 */
.igt-navbar-nav > li:hover > a,
.igt-navbar-nav > li > a:focus, .igt-navbar-nav > li.igt-open > a {
  background-color: #f5f5f5;
  color: #444;
  /* 3 */
  outline: none; }

/* OnClick */
.igt-navbar-nav > li > a:active {
  background-color: #ddd;
  color: #444; }

/* Active */
.igt-navbar-nav > li.igt-active > a {
  background-color: #f5f5f5;
  color: #444; }

/* Sub-objects: `igt-navbar-nav-subtitle`
 ========================================================================== */
.igt-navbar-nav .igt-navbar-nav-subtitle {
  line-height: 28px; }

.igt-navbar-nav-subtitle > div {
  margin-top: -6px;
  font-size: 10px;
  line-height: 12px; }

/* Sub-objects: `igt-navbar-content`, `igt-navbar-brand`, `igt-navbar-toggle`
 ========================================================================== */
/*
 * Imitate navbar items
 */
.igt-navbar-content,
.igt-navbar-brand,
.igt-navbar-toggle {
  box-sizing: border-box;
  display: block;
  height: 40px;
  padding: 0 15px;
  float: left; }

.igt-navbar-toggle {
  height: 3.5rem;
  float: right; }

/*
 * Helper to center all child elements vertically
 */
.igt-navbar-content:before,
.igt-navbar-brand:before,
.igt-navbar-toggle:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

/* Sub-objects: `igt-navbar-content`
 ========================================================================== */
/*
 * Better sibling spacing
 */
.igt-navbar-content + .igt-navbar-content:not(.igt-navbar-center) {
  padding-left: 0; }

/*
 * Link colors
 */
.igt-navbar-content > a:not([class]) {
  color: #07D; }

.igt-navbar-content > a:not([class]):hover {
  color: #059; }

/* Sub-objects: `igt-navbar-brand`
 ========================================================================== */
.igt-navbar-brand {
  font-size: 18px;
  color: #444;
  text-decoration: none; }

/*
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.igt-navbar-brand:hover,
.igt-navbar-brand:focus {
  color: #444;
  text-decoration: none;
  /* 2 */
  outline: none; }

/* Sub-object: `igt-navbar-toggle`
 ========================================================================== */
.igt-navbar-toggle {
  font-size: 18px;
  color: #444;
  text-decoration: none; }

/*
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.igt-navbar-toggle:hover,
.igt-navbar-toggle:focus {
  color: #444;
  text-decoration: none;
  /* 2 */
  outline: none; }

/*
 * 1. Center icon vertically
 */
.igt-navbar-toggle:after {
  content: "";
  font-family: FontAwesome;
  /* 1 */
  vertical-align: middle; }

.igt-navbar-toggle-alt:after {
  content: ""; }

/* Sub-object: `igt-navbar-center`
 ========================================================================== */
/*
 * The element with this class needs to be last child in the navbar
 * 1. This hack is needed because other float elements shift centered text
 */
.igt-navbar-center {
  float: none;
  text-align: center;
  /* 1 */
  max-width: 50%;
  margin-left: auto;
  margin-right: auto; }

/* Sub-object: `igt-navbar-flip`
 ========================================================================== */
.igt-navbar-flip {
  float: right; }

/* ========================================================================
   Component: Subnav
 ========================================================================== */
/*
 * 1. Gutter
 * 2. Remove default list style
 */
.igt-subnav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* 1 */
  margin-left: -10px;
  margin-top: -10px;
  /* 2 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions
 * 2. Horizontal gutter is using `padding` so `igt-width-*` classes can be applied
 * 3. Create position context for dropdowns
 */
.igt-subnav > * {
  /* 1 */
  -ms-flex: none;
  -webkit-flex: none;
  -webkit-box-flex: 0;
          flex: none;
  /* 2 */
  padding-left: 10px;
  margin-top: 10px;
  /* 3 */
  position: relative; }

/*
 * DEPRECATED IE9 Support
 */
.igt-subnav:before,
.igt-subnav:after {
  content: "";
  display: block;
  overflow: hidden; }

.igt-subnav:after {
  clear: both; }

.igt-subnav > * {
  float: left; }

/* Items
 ========================================================================== */
.igt-subnav > * > * {
  display: inline-block;
  color: #444; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 */
.igt-subnav > * > :hover,
.igt-subnav > * > :focus {
  color: #07D;
  text-decoration: none; }

/*
 * Active
 */
.igt-subnav > .igt-active > * {
  color: #07D; }

/* Modifier: 'subnav-line'
 ========================================================================== */
.igt-subnav-line > :before {
  content: "";
  display: inline-block;
  height: 10px;
  vertical-align: middle; }

.igt-subnav-line > :nth-child(n+2):before {
  margin-right: 10px;
  border-left: 1px solid #ddd; }

/* Modifier: 'subnav-pill'
 ========================================================================== */
.igt-subnav-pill > * > * {
  padding: 3px 9px; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.igt-subnav-pill > * > :hover,
.igt-subnav-pill > * > :focus {
  background: #eee;
  color: #444;
  text-decoration: none;
  /* 2 */
  outline: none; }

/*
 * Active
 * `li` needed for higher specificity to override hover
 */
.igt-subnav-pill > .igt-active > * {
  background: #39a448;
  color: #fff; }

/* Disabled state
 ========================================================================== */
.igt-subnav > .igt-disabled > * {
  background: none;
  color: #999;
  text-decoration: none;
  cursor: text; }

/* ========================================================================
   Component: Breadcrumb
 ========================================================================== */
/*
 * 1. Remove default list style
 * 2. Remove whitespace between child elements when using `inline-block`
 */
.igt-breadcrumb {
  /* 1 */
  padding: 0;
  list-style: none;
  /* 2 */
  font-size: 0.001px; }

/* Items
 ========================================================================== */
/*
 * Reset whitespace hack
 */
.igt-breadcrumb > li {
  font-size: 1rem;
  vertical-align: top; }

.igt-breadcrumb > li,
.igt-breadcrumb > li > a,
.igt-breadcrumb > li > span {
  display: inline-block; }

.igt-breadcrumb > li:nth-child(n+2):before {
  content: "/";
  display: inline-block;
  margin: 0 8px; }

/*
 * Disabled
 */
.igt-breadcrumb > li:not(.igt-active) > span {
  color: #999; }

/* ========================================================================
   Component: Pagination
 ========================================================================== */
/*
 * 1. Remove default list style
 * 2. Center pagination by default
 * 3. Remove whitespace between child elements when using `inline-block`
 */
.igt-pagination {
  /* 1 */
  padding: 0;
  list-style: none;
  /* 2 */
  text-align: center;
  /* 3 */
  font-size: 0.001px; }

/*
 * Micro clearfix
 * Needed if `igt-pagination-previous` or `igt-pagination-next` sub-objects are used
 */
.igt-pagination:before,
.igt-pagination:after {
  content: "";
  display: table; }

.igt-pagination:after {
  clear: both; }

/* Items
 ========================================================================== */
/*
 * 1. Reset whitespace hack
 * 2. Remove the gap at the bottom of it container
 */
.igt-pagination > li {
  display: inline-block;
  /* 1 */
  font-size: 1rem;
  /* 2 */
  vertical-align: top; }

.igt-pagination > li:nth-child(n+2) {
  margin-left: 5px; }

/*
 * 1. Makes pagination more robust against different box-sizing use
 * 2. Reset text-align to center if alignment modifier is used
 */
.igt-pagination > li > a,
.igt-pagination > li > span {
  display: inline-block;
  min-width: 16px;
  padding: 5px 7px;
  line-height: 20px;
  text-decoration: none;
  /* 1 */
  box-sizing: content-box;
  /* 2 */
  text-align: center;
  border-radius: 3px; }

/*
 * Links
 */
.igt-pagination > li > a {
  background: #f5f5f5;
  color: #444; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.igt-pagination > li > a:hover,
.igt-pagination > li > a:focus {
  background-color: #eee;
  color: #444;
  /* 2 */
  outline: none; }

/* OnClick */
.igt-pagination > li > a:active {
  background-color: #eee;
  color: #444; }

/*
 * Active
 */
.igt-pagination > .igt-active > span {
  background: #39a448;
  color: #fff; }

/*
 * Disabled
 */
.igt-pagination > .igt-disabled > span {
  background-color: #f5f5f5;
  color: #999; }

/* Previous and next navigation
 ========================================================================== */
.igt-pagination-previous {
  float: left; }

.igt-pagination-next {
  float: right; }

/* Alignment modifiers
 ========================================================================== */
.igt-pagination-left {
  text-align: left; }

.igt-pagination-right {
  text-align: right; }

/* ========================================================================
   Component: Tab
 ========================================================================== */
.igt-tab {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #ddd; }

/*
 * Micro clearfix on the deepest container
 */
.igt-tab:before,
.igt-tab:after {
  content: "";
  display: table; }

.igt-tab:after {
  clear: both; }

/*
 * Items
 * 1. Create position context for dropdowns
 */
.igt-tab > li {
  margin-bottom: -1px;
  float: left;
  /* 1 */
  position: relative; }

.igt-tab > li > a {
  display: block;
  padding: 8px 12px 8px 12px;
  border: 1px solid transparent;
  border-bottom-width: 0;
  color: #39a448;
  text-decoration: none; }

.igt-tab > li:nth-child(n+2) > a {
  margin-left: 5px; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Also apply if dropdown is opened
 * 3. Remove default focus style
 */
.igt-tab > li > a:hover,
.igt-tab > li > a:focus, .igt-tab > li.igt-open > a {
  border-color: #f5f5f5;
  background: #f5f5f5;
  color: #4ec25f;
  /* 2 */
  outline: none; }

.igt-tab > li:not(.igt-active) > a:hover,
.igt-tab > li:not(.igt-active) > a:focus,
.igt-tab > li.igt-open:not(.igt-active) > a {
  margin-bottom: 1px;
  padding-bottom: 7px; }

/* Active */
.igt-tab > li.igt-active > a {
  border-color: #ddd;
  border-bottom-color: transparent;
  background: #fff;
  color: #444; }

/* Disabled */
.igt-tab > li.igt-disabled > a {
  color: #999;
  cursor: text; }

.igt-tab > li.igt-disabled > a:hover,
.igt-tab > li.igt-disabled > a:focus,
.igt-tab > li.igt-disabled.igt-active > a {
  background: none;
  border-color: transparent; }

/* Modifier: 'tab-flip'
 ========================================================================== */
.igt-tab-flip > li {
  float: right; }

.igt-tab-flip > li:nth-child(n+2) > a {
  margin-left: 0;
  margin-right: 5px; }

/* Modifier: 'tab-responsive'
 ========================================================================== */
.igt-tab > li.igt-tab-responsive > a {
  margin-left: 0;
  margin-right: 0; }

/*
 * Icon
 */
.igt-tab-responsive > a:before {
  content: "\f0c9\00a0";
  font-family: FontAwesome; }

/* Modifier: 'tab-center'
 ========================================================================== */
.igt-tab-center {
  border-bottom: 1px solid #ddd; }

.igt-tab-center-bottom {
  border-bottom: none;
  border-top: 1px solid #ddd; }

.igt-tab-center:before,
.igt-tab-center:after {
  content: "";
  display: table; }

.igt-tab-center:after {
  clear: both; }

/*
 * 1. Using `right` to prevent vertical scrollbar caused by centering if to many tabs
 */
.igt-tab-center .igt-tab {
  position: relative;
  right: 50%;
  border: none;
  float: right; }

.igt-tab-center .igt-tab > li {
  position: relative;
  right: -50%; }

.igt-tab-center .igt-tab > li > a {
  text-align: center; }

/* Modifier: 'tab-bottom'
 ========================================================================== */
.igt-tab-bottom {
  border-top: 1px solid #ddd;
  border-bottom: none; }

.igt-tab-bottom > li {
  margin-top: -1px;
  margin-bottom: 0; }

.igt-tab-bottom > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom-width: 1px;
  border-top-width: 0; }

.igt-tab-bottom > li:not(.igt-active) > a:hover,
.igt-tab-bottom > li:not(.igt-active) > a:focus,
.igt-tab-bottom > li.igt-open:not(.igt-active) > a {
  margin-bottom: 0;
  margin-top: 1px;
  padding-bottom: 8px;
  padding-top: 7px; }

.igt-tab-bottom > li.igt-active > a {
  border-top-color: transparent;
  border-bottom-color: #ddd; }

/* Modifier: 'tab-grid'
 ========================================================================== */
/*
 * 1. Create position context to prevent hidden border because of negative `z-index`
 */
.igt-tab-grid {
  margin-left: -5px;
  border-bottom: none;
  /* 1 */
  position: relative;
  z-index: 0; }

.igt-tab-grid:before {
  display: block;
  position: absolute;
  left: 5px;
  right: 0;
  bottom: -1px;
  border-top: 1px solid #ddd;
  /* 1 */
  z-index: -1; }

.igt-tab-grid > li:first-child > a {
  margin-left: 5px; }

.igt-tab-grid > li > a {
  text-align: center; }

/*
 * If `igt-tab-bottom`
 */
.igt-tab-grid.igt-tab-bottom {
  border-top: none; }

.igt-tab-grid.igt-tab-bottom:before {
  top: -1px;
  bottom: auto; }

/* Modifier: 'tab-left', 'tab-right'
 ========================================================================== */
/* Tablet and bigger */
@media (min-width: 768px) {
  .igt-tab-left,
  .igt-tab-right {
    border-bottom: none; }
  .igt-tab-left > li,
  .igt-tab-right > li {
    margin-bottom: 0;
    float: none; }
  .igt-tab-left > li > a,
  .igt-tab-right > li > a {
    padding-top: 8px;
    padding-bottom: 8px; }
  .igt-tab-left > li:nth-child(n+2) > a,
  .igt-tab-right > li:nth-child(n+2) > a {
    margin-left: 0;
    margin-top: 5px; }
  .igt-tab-left > li.igt-active > a,
  .igt-tab-right > li.igt-active > a {
    border-color: #ddd; }
  /*
     * Modifier: 'tab-left'
     */
  .igt-tab-left {
    border-right: 1px solid #ddd; }
  .igt-tab-left > li {
    margin-right: -1px; }
  .igt-tab-left > li > a {
    border-bottom-width: 1px;
    border-right-width: 0; }
  .igt-tab-left > li:not(.igt-active) > a:hover,
  .igt-tab-left > li:not(.igt-active) > a:focus {
    margin-bottom: 0;
    margin-right: 1px;
    padding-bottom: 8px;
    padding-right: 11px; }
  .igt-tab-left > li.igt-active > a {
    border-right-color: transparent; }
  /*
     * Modifier: 'tab-right'
     */
  .igt-tab-right {
    border-left: 1px solid #ddd; }
  .igt-tab-right > li {
    margin-left: -1px; }
  .igt-tab-right > li > a {
    border-bottom-width: 1px;
    border-left-width: 0; }
  .igt-tab-right > li:not(.igt-active) > a:hover,
  .igt-tab-right > li:not(.igt-active) > a:focus {
    margin-bottom: 0;
    margin-left: 1px;
    padding-bottom: 8px;
    padding-left: 11px; }
  .igt-tab-right > li.igt-active > a {
    border-left-color: transparent; } }

/* ========================================================================
   Component: Thumbnav
 ========================================================================== */
/*
 * 1. Gutter
 * 2. Remove default list style
 */
.igt-thumbnav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* 1 */
  margin-left: -10px;
  margin-top: -10px;
  /* 2 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions
 * 2. Horizontal gutter is using `padding` so `igt-width-*` classes can be applied
 */
.igt-thumbnav > * {
  /* 1 */
  -ms-flex: none;
  -webkit-flex: none;
  -webkit-box-flex: 0;
          flex: none;
  /* 2 */
  padding-left: 10px;
  margin-top: 10px; }

/*
 * DEPRECATED IE9 Support
 */
.igt-thumbnav:before,
.igt-thumbnav:after {
  content: "";
  display: block;
  overflow: hidden; }

.igt-thumbnav:after {
  clear: both; }

.igt-thumbnav > * {
  float: left; }

/* Items
 ========================================================================== */
.igt-thumbnav > * > * {
  display: block;
  background: #fff; }

.igt-thumbnav > * > * > img {
  opacity: 0.7;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }

/*
 * Hover
 */
.igt-thumbnav > * > :hover > img,
.igt-thumbnav > * > :focus > img {
  opacity: 1; }

/*
 * Active
 */
.igt-thumbnav > .igt-active > * > img {
  opacity: 1; }

/* ========================================================================
   Component: List
 ========================================================================== */
.igt-list {
  padding: 0;
  list-style: none; }

/*
 * Micro clearfix to make list more robust
 */
.igt-list > li:before,
.igt-list > li:after {
  content: "";
  display: table; }

.igt-list > li:after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.igt-list > li > :last-child {
  margin-bottom: 0; }

/*
 * Nested lists
 */
.igt-list ul {
  margin: 0;
  padding-left: 20px;
  list-style: none; }

/* Modifier: `igt-list-line`
 ========================================================================== */
.igt-list-line > li:nth-child(n+2) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #eee; }

/* Modifier: `igt-list-striped`
 ========================================================================== */
.igt-list-striped > li {
  padding: 5px 5px; }

.igt-list-striped > li:nth-of-type(odd) {
  background: #f5f5f5; }

/* Modifier: `igt-list-space`
 ========================================================================== */
.igt-list-space > li:nth-child(n+2) {
  margin-top: 10px; }

/* ========================================================================
   Component: Description list
 ========================================================================== */
/* Modifier: `igt-description-list-horizontal`
 ========================================================================== */
/* Tablet and bigger */
@media (min-width: 768px) {
  .igt-description-list-horizontal {
    overflow: hidden; }
  .igt-description-list-horizontal > dt {
    width: 160px;
    float: left;
    clear: both;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .igt-description-list-horizontal > dd {
    margin-left: 180px; } }

/* Modifier: `igt-description-list-line`
 ========================================================================== */
.igt-description-list-line > dt {
  font-weight: normal; }

.igt-description-list-line > dt:nth-child(n+2) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #ddd; }

.igt-description-list-line > dd {
  color: #999; }

/* ========================================================================
   Component: Table
 ========================================================================== */
/*
 * 1. Remove most spacing between table cells.
 * 2. Block element behavior
 * 3. Style
 */
.igt-table {
  /* 1 */
  border-collapse: collapse;
  border-spacing: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  margin-bottom: 15px; }

/*
 * Add margin if adjacent element
 */
* + .igt-table {
  margin-top: 15px; }

.igt-table th,
.igt-table td {
  padding: 8px 8px;
  border-bottom: 1px solid #eee; }

/*
 * Set alignment
 */
.igt-table th {
  text-align: left; }

.igt-table td {
  vertical-align: top; }

.igt-table thead th {
  vertical-align: bottom; }

/*
 * Caption and footer
 */
.igt-table caption,
.igt-table tfoot {
  font-size: 12px;
  font-style: italic; }

.igt-table caption {
  text-align: left;
  color: #999; }

/*
 * Active State
 */
.igt-table tbody tr.igt-active {
  background: #EEE; }

/* Sub-modifier: `igt-table-middle`
 ========================================================================== */
.igt-table-middle,
.igt-table-middle td {
  vertical-align: middle !important; }

/* Modifier: `igt-table-striped`
 ========================================================================== */
.igt-table-striped tbody tr:nth-of-type(odd) {
  background: #f5f5f5; }

/* Modifier: `igt-table-condensed`
 ========================================================================== */
.igt-table-condensed td {
  padding: 4px 8px; }

/* Modifier: `igt-table-hover`
 ========================================================================== */
.igt-table-hover tbody tr:hover {
  background: #EEE; }

/* ========================================================================
   Component: Form
 ========================================================================== */
/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `button`, `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Correct `font` properties and `color` not being inherited.
 */
.igt-form input,
.igt-form select,
.igt-form textarea {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 5px;
  /* 4 */
  font: inherit;
  color: inherit; }

/*
 * Address inconsistent `text-transform` inheritance which is only inherit in Firefox
 */
.igt-form select {
  text-transform: none; }

/*
 * 1. Correct `font` properties not being inherited.
 * 2. Don't inherit the `font-weight` and use `bold` instead.
 * NOTE: Both declarations don't work in Chrome, Safari and Opera.
 */
.igt-form optgroup {
  /* 1 */
  font: inherit;
  /* 2 */
  font-weight: bold; }

/*
 * Removes inner padding and border in Firefox 4+.
 */
.igt-form input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * Removes excess padding in IE 8/9/10.
 */
.igt-form input[type="checkbox"],
.igt-form input[type="radio"] {
  padding: 0; }

/*
 * Improves consistency of cursor style for clickable elements
 */
.igt-form input[type="checkbox"]:not(:disabled),
.igt-form input[type="radio"]:not(:disabled) {
  cursor: pointer; }

/*
 * Remove default style in iOS.
 */
.igt-form textarea,
.igt-form input:not([type]),
.igt-form input[type="text"],
.igt-form input[type="password"],
.igt-form input[type="email"],
.igt-form input[type="url"],
.igt-form input[type="search"],
.igt-form input[type="tel"],
.igt-form input[type="number"],
.igt-form input[type="datetime"] {
  -webkit-appearance: none; }

/*
 * Remove inner padding and search cancel button in Chrome, Safari and Opera on OS X.
 */
.igt-form input[type="search"]::-webkit-search-cancel-button,
.igt-form input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/*
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
.igt-form input[type="number"]::-webkit-inner-spin-button,
.igt-form input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/*
 * Define consistent border, margin, and padding.
 */
.igt-form fieldset {
  border: none;
  margin: 0;
  padding: 0; }

/*
 * 1. Remove default vertical scrollbar in IE 8/9/10/11.
 * 2. Improve readability and alignment in all browsers.
 */
.igt-form textarea {
  /* 1 */
  overflow: auto;
  /* 2 */
  vertical-align: top; }

/*
 * Removes placeholder transparency in Firefox.
 */
.igt-form ::-moz-placeholder {
  opacity: 1; }

/*
 * Removes `box-shadow` for invalid controls in Firefox.
 */
.igt-form :invalid {
  box-shadow: none; }

/*
 * Vertical alignment
 */
.igt-form input:not([type="radio"]):not([type="checkbox"]),
.igt-form select {
  vertical-align: middle; }

/* Style
 ========================================================================== */
/*
 * Remove margin from the last-child
 */
.igt-form > :last-child {
  margin-bottom: 0; }

/*
 * Controls
 * Except for `range`, `radio`, `checkbox`, `file`, `submit`, `reset`, `button` and `image`
 * 1. Must be `height` because `min-height` is not working in OSX
 * 2. Responsiveness: Sets a maximum width relative to the parent to scale on narrower viewports
 * 3. Vertical `padding` needed for `select` elements in Firefox
 * 4. Style
 */
.igt-form select,
.igt-form textarea,
.igt-form input:not([type]),
.igt-form input[type="text"],
.igt-form input[type="password"],
.igt-form input[type="datetime"],
.igt-form input[type="datetime-local"],
.igt-form input[type="date"],
.igt-form input[type="month"],
.igt-form input[type="time"],
.igt-form input[type="week"],
.igt-form input[type="number"],
.igt-form input[type="email"],
.igt-form input[type="url"],
.igt-form input[type="search"],
.igt-form input[type="tel"],
.igt-form input[type="color"] {
  /* 1 */
  height: 40px;
  /* 2 */
  max-width: 100%;
  /* 3 */
  padding: 5px 10px;
  /* 4 */
  border: 2px solid #ddd;
  background: #fff;
  color: #444;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s; }
  .igt-form select:focus,
  .igt-form textarea:focus,
  .igt-form input:not([type]):focus,
  .igt-form input[type="text"]:focus,
  .igt-form input[type="password"]:focus,
  .igt-form input[type="datetime"]:focus,
  .igt-form input[type="datetime-local"]:focus,
  .igt-form input[type="date"]:focus,
  .igt-form input[type="month"]:focus,
  .igt-form input[type="time"]:focus,
  .igt-form input[type="week"]:focus,
  .igt-form input[type="number"]:focus,
  .igt-form input[type="email"]:focus,
  .igt-form input[type="url"]:focus,
  .igt-form input[type="search"]:focus,
  .igt-form input[type="tel"]:focus,
  .igt-form input[type="color"]:focus {
    border-color: #b7b7b7;
    outline: 0;
    background: #fff !important;
    color: #444; }
  .igt-form select:disabled,
  .igt-form textarea:disabled,
  .igt-form input:not([type]):disabled,
  .igt-form input[type="text"]:disabled,
  .igt-form input[type="password"]:disabled,
  .igt-form input[type="datetime"]:disabled,
  .igt-form input[type="datetime-local"]:disabled,
  .igt-form input[type="date"]:disabled,
  .igt-form input[type="month"]:disabled,
  .igt-form input[type="time"]:disabled,
  .igt-form input[type="week"]:disabled,
  .igt-form input[type="number"]:disabled,
  .igt-form input[type="email"]:disabled,
  .igt-form input[type="url"]:disabled,
  .igt-form input[type="search"]:disabled,
  .igt-form input[type="tel"]:disabled,
  .igt-form input[type="color"]:disabled {
    border-color: #ddd;
    background-color: #f5f5f5;
    color: #999; }

/*
 * Placeholder
 */
.igt-form :-ms-input-placeholder {
  color: #999 !important; }

.igt-form ::-moz-placeholder {
  color: #999; }

.igt-form ::-webkit-input-placeholder {
  color: #999; }

.igt-form :disabled:-ms-input-placeholder {
  color: #999 !important; }

.igt-form :disabled::-moz-placeholder {
  color: #999; }

.igt-form :disabled::-webkit-input-placeholder {
  color: #999; }

/*
 * Legend
 * 1. Behave like block element
 * 2. Correct `color` not being inherited in IE 8/9/10/11.
 * 3. Remove padding
 * 4. `margin-bottom` is not working in Safari and Opera.
 *    Using `padding` and :after instead to create the border
 * 5. Style
 */
.igt-form legend {
  /* 1 */
  width: 100%;
  /* 2 */
  border: 0;
  /* 3 */
  padding: 0;
  /* 4 */
  padding-bottom: 15px;
  /* 5 */
  font-size: 18px;
  line-height: 30px; }

/*
 * 1. Fixes IE9
 */
.igt-form legend:after {
  content: "";
  display: block;
  border-bottom: 1px solid #ddd;
  /* 1 */
  width: 100%; }

/* Size modifiers
 * Higher specificity needed to override defaults
 ========================================================================== */
select.igt-form-small,
textarea.igt-form-small,
input[type].igt-form-small,
input:not([type]).igt-form-small {
  height: 25px;
  padding: 3px 3px;
  font-size: 12px; }

select.igt-form-large,
textarea.igt-form-large,
input[type].igt-form-large,
input:not([type]).igt-form-large {
  height: 40px;
  padding: 8px 6px;
  font-size: 16px; }

/* Reset height
 * Must be after size modifiers
 ========================================================================== */
.igt-form textarea,
.igt-form select[multiple],
.igt-form select[size] {
  height: auto; }

/* Validation states
 * Using !important to keep the selector simple
 ========================================================================== */
/*
 * Error state
 */
.igt-form-danger {
  border-color: #dc8d99 !important;
  background: #fff7f8 !important;
  color: #d85030 !important; }

/*
 * Success state
 */
.igt-form-success {
  border-color: #8ec73b !important;
  background: #fafff2 !important;
  color: #659f13 !important; }

/* Style modifiers
 * Using !important to keep the selector simple
 ========================================================================== */
/*
 * Blank form
 */
.igt-form-blank {
  border-color: transparent !important;
  border-style: dashed !important;
  background: none !important; }

.igt-form-blank:focus {
  border-color: #ddd !important; }

/* Size sub-modifiers
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.igt-form-width-mini {
  width: 40px; }

select.igt-form-width-mini {
  width: 65px; }

.igt-form-width-small {
  width: 130px; }

.igt-form-width-medium {
  width: 200px; }

.igt-form-width-large {
  width: 500px; }

/* Sub-objects: `igt-form-row`
 * Groups labels and controls in rows
 ========================================================================== */
/*
 * Micro clearfix
 * Needed for `igt-form-horizontal` modifier
 */
.igt-form-row:before,
.igt-form-row:after {
  content: "";
  display: table; }

.igt-form-row:after {
  clear: both; }

/*
 * Vertical gutter
 */
.igt-form-row + .igt-form-row {
  margin-top: 15px; }

/* Help text
 * Sub-object: `igt-form-help-inline`, `igt-form-help-block`
 ========================================================================== */
.igt-form-help-inline {
  display: inline-block;
  margin: 0 0 0 10px; }

.igt-form-help-block {
  margin: 5px 0 0 0; }

/* Controls content
 * Sub-object: `igt-form-controls`, `igt-form-controls-condensed`
 ========================================================================== */
/*
 * Remove margins
 */
.igt-form-controls > :first-child {
  margin-top: 0; }

.igt-form-controls > :last-child {
  margin-bottom: 0; }

/*
 * Group controls and text into blocks with a small spacing between blocks
 */
.igt-form-controls-condensed {
  margin: 5px 0; }

/* Modifier: `igt-form-stacked`
 * Requires sub-object: `igt-form-label`
 ========================================================================== */
.igt-form-stacked .igt-form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold; }

/* Modifier: `igt-form-horizontal`
 * Requires sub-objects: `igt-form-label`, `igt-form-controls`
 ========================================================================== */
/* Tablet portrait and smaller */
@media (max-width: 959px) {
  /* Behave like `igt-form-stacked` */
  .igt-form-horizontal .igt-form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold; } }

/* Desktop and bigger */
@media (min-width: 960px) {
  .igt-form-horizontal .igt-form-label {
    width: 200px;
    margin-top: 5px;
    float: left; }
  .igt-form-horizontal .igt-form-controls {
    margin-left: 215px; }
  /* Better vertical alignment if controls are checkboxes and radio buttons with text */
  .igt-form-horizontal .igt-form-controls-text {
    padding-top: 5px; } }

/* Sub-object: `igt-form-icon`
 ========================================================================== */
/*
 * 1. Container width fits its content
 * 2. Create position context
 * 3. Prevent `inline-block` consequences
 */
.igt-form-icon {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%; }

/*
 * 1. Make form element clickable through icon
 */
.igt-form-icon > [class*='igt-icon-'] {
  position: absolute;
  top: 50%;
  width: 30px;
  margin-top: -7px;
  font-size: 14px;
  color: #999;
  text-align: center;
  /* 1 */
  pointer-events: none; }

.igt-form-icon:not(.igt-form-icon-flip) > input {
  padding-left: 30px !important; }

/*
 * Sub-modifier: `igt-form-icon-flip`
 */
.igt-form-icon-flip > [class*='igt-icon-'] {
  right: 0; }

.igt-form-icon-flip > input {
  padding-right: 30px !important; }

/* ========================================================================
   Component: Button
 ========================================================================== */
/*
 * Removes inner padding and border in Firefox 4+.
 */
.igt-button::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * 1. Correct inability to style clickable `input` types in iOS.
 * 2. Remove margins in Chrome, Safari and Opera.
 * 3. Remove borders for `button`.
 * 4. Address `overflow` set to `hidden` in IE 8/9/10/11.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Address inconsistent `text-transform` inheritance which is only inherit in Firefox and IE
 * 7. Style
 * 8. `line-height` is used to create a height
 * 9. `min-height` is necessary for `input` elements in Firefox and Opera because `line-height` is not working.
 * 10. Reset button group whitespace hack
 * 11. Required for `a`.
 */
.igt-button {
  /* 1 */
  -webkit-appearance: none;
  /* 2 */
  margin: 0;
  /* 3 */
  border: none;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: #3c474b;
  /* 6 */
  text-transform: none;
  /* 7 */
  display: inline-block;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 1rem;
  background: #eee;
  vertical-align: middle;
  position: relative;
  /* 8 */
  line-height: 2.44rem;
  /* 9 */
  min-height: 2.44rem;
  /* 10 */
  font-size: 1rem;
  /* 11 */
  text-decoration: none;
  text-align: center; }

.igt-button:not(:disabled) {
  cursor: pointer; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 * 3. Required for `a` elements
 */
.igt-button:hover,
.igt-button:focus {
  background-color: #f5f5f5;
  color: #3c474b;
  /* 2 */
  outline: none;
  /* 3 */
  text-decoration: none; }

/* Active */
.igt-button:active,
.igt-button.igt-active {
  background-color: #ddd;
  color: #3c474b; }

/* Color modifiers
 ========================================================================== */
/*
 * Modifier: `igt-button-primary`
 */
.igt-button-primary {
  background-color: #39a448;
  color: #fff;
  padding-left: 1.728rem;
  padding-right: 3.456rem;
  box-shadow: 0 2px 2px rgba(60, 71, 75, 0.33); }

.igt-button-primary:after {
  content: "";
  font-family: FontAwesome;
  vertical-align: middle;
  position: absolute;
  right: 1.728rem; }

/* Hover */
.igt-button-primary:hover,
.igt-button-primary:focus {
  background-color: #4ec25f;
  color: #fff; }

/* Active */
.igt-button-primary:active,
.igt-button-primary.igt-active {
  background-color: #2c7e37;
  color: #fff; }

/*
 * Modifier: `igt-button-primary-reversed`
 */
.igt-button-primary-reversed {
  background-color: #fff;
  color: #39a448;
  padding-left: 1.728rem;
  padding-right: 3.456rem;
  box-shadow: 0 2px 2px rgba(60, 71, 75, 0.33); }

.igt-button-primary-reversed:after {
  content: "";
  font-family: FontAwesome;
  vertical-align: middle;
  position: absolute;
  right: 1.728rem; }

/* Hover */
.igt-button-primary-reversed:hover,
.igt-button-primary-reversed:focus {
  background-color: #fff;
  color: #4ec25f; }

/* Active */
.igt-button-primary-reversed:active,
.igt-button-primary-reversed.igt-active {
  background-color: #fff;
  color: #2c7e37; }

/*
 * Modifier: `igt-button-success`
 */
.igt-button-success {
  background-color: #8cc14c;
  color: #fff; }

/* Hover */
.igt-button-success:hover,
.igt-button-success:focus {
  background-color: #8ec73b;
  color: #fff; }

/* Active */
.igt-button-success:active,
.igt-button-success.igt-active {
  background-color: #72ae41;
  color: #fff; }

/*
 * Modifier: `igt-button-danger`
 */
.igt-button-danger {
  background-color: #da314b;
  color: #fff; }

/* Hover */
.igt-button-danger:hover,
.igt-button-danger:focus {
  background-color: #e4354f;
  color: #fff; }

/* Active */
.igt-button-danger:active,
.igt-button-danger.igt-active {
  background-color: #c91032;
  color: #fff; }

/* Disabled state
 * Overrides also the color modifiers
 ========================================================================== */
/* Equal for all button types */
.igt-button:disabled {
  background-color: #f5f5f5;
  color: #999; }

/* Modifier: `igt-button-link`
 ========================================================================== */
/* Reset */
.igt-button-link,
.igt-button-link:hover,
.igt-button-link:focus,
.igt-button-link:active,
.igt-button-link.igt-active,
.igt-button-link:disabled {
  border-color: transparent;
  background: none; }

/* Color */
.igt-button-link {
  color: #07D; }

.igt-button-link:hover,
.igt-button-link:focus,
.igt-button-link:active,
.igt-button-link.igt-active {
  color: #059;
  text-decoration: underline; }

.igt-button-link:disabled {
  color: #999; }

/* Focus */
.igt-button-link:focus {
  outline: 1px dotted; }

/* Size modifiers
 ========================================================================== */
.igt-button-mini {
  min-height: 1rem;
  padding: 0 0.5rem;
  line-height: 1rem;
  font-size: 0.833rem; }

.igt-button-small {
  min-height: 1.2rem;
  padding: 0 0.75rem;
  line-height: 1.2rem;
  font-size: 1rem; }

.igt-button-large {
  min-height: 1.728rem;
  padding: 0 1.728rem;
  line-height: 1.728rem;
  font-size: 1.2rem; }

/* Sub-object `igt-button-group`
 ========================================================================== */
/*
 * 1. Behave like buttons
 * 2. Create position context for dropdowns
 * 3. Remove whitespace between child elements when using `inline-block`
 * 4. Prevent buttons from wrapping
 * 5. Remove whitespace between child elements when using `inline-block`
 */
.igt-button-group {
  /* 1 */
  display: inline-block;
  vertical-align: middle;
  /* 2 */
  position: relative;
  /* 3 */
  font-size: 0.001px;
  /* 4 */
  white-space: nowrap; }

.igt-button-group > * {
  display: inline-block; }

/* 5 */
.igt-button-group .igt-button {
  vertical-align: top; }

/* Sub-object: `igt-button-dropdown`
 ========================================================================== */
/*
 * 1. Behave like buttons
 * 2. Create position context for dropdowns
 */
.igt-button-dropdown {
  /* 1 */
  display: inline-block;
  vertical-align: middle;
  /* 2 */
  position: relative; }

/* ========================================================================
   Component: Icon
 ========================================================================== */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

/*
 * 1. Allow margin
 * 2. Prevent inherit font style
 * 4. Correct line-height
 * 5. Better font rendering
 * 6. Remove `text-decoration` for anchors
 */
[class*='igt-icon-'] {
  font-family: FontAwesome;
  /* 1 */
  display: inline-block;
  /* 2 */
  font-weight: normal;
  font-style: normal;
  /* 4 */
  line-height: 1;
  /* 5 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* 6 */
[class*='igt-icon-'],
[class*='igt-icon-']:hover,
[class*='igt-icon-']:focus {
  text-decoration: none; }

/* Size modifiers
 ========================================================================== */
.igt-icon-small {
  font-size: 150%;
  vertical-align: -10%; }

.igt-icon-medium {
  font-size: 200%;
  vertical-align: -16%; }

.igt-icon-large {
  font-size: 250%;
  vertical-align: -22%; }

/* Modifier: `igt-icon-justify`
 ========================================================================== */
.igt-icon-justify {
  width: 1em;
  text-align: center; }

/* Modifier: `igt-icon-spin`
 ========================================================================== */
.igt-icon-spin {
  display: inline-block;
  -webkit-animation: igt-rotate 2s infinite linear;
  animation: igt-rotate 2s infinite linear; }

/* Modifier: `igt-icon-hover`
 ========================================================================== */
.igt-icon-hover {
  color: #999; }

/*
 * Hover
 */
.igt-icon-hover:hover {
  color: #444; }

/* Modifier: `igt-icon-button`
 ========================================================================== */
.igt-icon-button {
  box-sizing: border-box;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: #eee;
  line-height: 35px;
  color: #444;
  font-size: 18px;
  text-align: center; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.igt-icon-button:hover,
.igt-icon-button:focus {
  background-color: #f5f5f5;
  color: #444;
  /* 2 */
  outline: none; }

/* Active */
.igt-icon-button:active {
  background-color: #ddd;
  color: #444; }

/* Icon mapping
 ========================================================================== */
.igt-icon-glass:before {
  content: "\f000"; }

.igt-icon-music:before {
  content: "\f001"; }

.igt-icon-search:before {
  content: "\f002"; }

.igt-icon-envelope-o:before {
  content: "\f003"; }

.igt-icon-heart:before {
  content: "\f004"; }

.igt-icon-star:before {
  content: "\f005"; }

.igt-icon-star-o:before {
  content: "\f006"; }

.igt-icon-user:before {
  content: "\f007"; }

.igt-icon-film:before {
  content: "\f008"; }

.igt-icon-th-large:before {
  content: "\f009"; }

.igt-icon-th:before {
  content: "\f00a"; }

.igt-icon-th-list:before {
  content: "\f00b"; }

.igt-icon-check:before {
  content: "\f00c"; }

.igt-icon-remove:before,
.igt-icon-close:before,
.igt-icon-times:before {
  content: "\f00d"; }

.igt-icon-search-plus:before {
  content: "\f00e"; }

.igt-icon-search-minus:before {
  content: "\f010"; }

.igt-icon-power-off:before {
  content: "\f011"; }

.igt-icon-signal:before {
  content: "\f012"; }

.igt-icon-gear:before,
.igt-icon-cog:before {
  content: "\f013"; }

.igt-icon-trash-o:before {
  content: "\f014"; }

.igt-icon-home:before {
  content: "\f015"; }

.igt-icon-file-o:before {
  content: "\f016"; }

.igt-icon-clock-o:before {
  content: "\f017"; }

.igt-icon-road:before {
  content: "\f018"; }

.igt-icon-download:before {
  content: "\f019"; }

.igt-icon-arrow-circle-o-down:before {
  content: "\f01a"; }

.igt-icon-arrow-circle-o-up:before {
  content: "\f01b"; }

.igt-icon-inbox:before {
  content: "\f01c"; }

.igt-icon-play-circle-o:before {
  content: "\f01d"; }

.igt-icon-rotate-right:before,
.igt-icon-repeat:before {
  content: "\f01e"; }

.igt-icon-refresh:before {
  content: "\f021"; }

.igt-icon-list-alt:before {
  content: "\f022"; }

.igt-icon-lock:before {
  content: "\f023"; }

.igt-icon-flag:before {
  content: "\f024"; }

.igt-icon-headphones:before {
  content: "\f025"; }

.igt-icon-volume-off:before {
  content: "\f026"; }

.igt-icon-volume-down:before {
  content: "\f027"; }

.igt-icon-volume-up:before {
  content: "\f028"; }

.igt-icon-qrcode:before {
  content: "\f029"; }

.igt-icon-barcode:before {
  content: "\f02a"; }

.igt-icon-tag:before {
  content: "\f02b"; }

.igt-icon-tags:before {
  content: "\f02c"; }

.igt-icon-book:before {
  content: "\f02d"; }

.igt-icon-bookmark:before {
  content: "\f02e"; }

.igt-icon-print:before {
  content: "\f02f"; }

.igt-icon-camera:before {
  content: "\f030"; }

.igt-icon-font:before {
  content: "\f031"; }

.igt-icon-bold:before {
  content: "\f032"; }

.igt-icon-italic:before {
  content: "\f033"; }

.igt-icon-text-height:before {
  content: "\f034"; }

.igt-icon-text-width:before {
  content: "\f035"; }

.igt-icon-align-left:before {
  content: "\f036"; }

.igt-icon-align-center:before {
  content: "\f037"; }

.igt-icon-align-right:before {
  content: "\f038"; }

.igt-icon-align-justify:before {
  content: "\f039"; }

.igt-icon-list:before {
  content: "\f03a"; }

.igt-icon-dedent:before,
.igt-icon-outdent:before {
  content: "\f03b"; }

.igt-icon-indent:before {
  content: "\f03c"; }

.igt-icon-video-camera:before {
  content: "\f03d"; }

.igt-icon-photo:before,
.igt-icon-image:before,
.igt-icon-picture-o:before {
  content: "\f03e"; }

.igt-icon-pencil:before {
  content: "\f040"; }

.igt-icon-map-marker:before {
  content: "\f041"; }

.igt-icon-adjust:before {
  content: "\f042"; }

.igt-icon-tint:before {
  content: "\f043"; }

.igt-icon-edit:before,
.igt-icon-pencil-square-o:before {
  content: "\f044"; }

.igt-icon-share-square-o:before {
  content: "\f045"; }

.igt-icon-check-square-o:before {
  content: "\f046"; }

.igt-icon-arrows:before {
  content: "\f047"; }

.igt-icon-step-backward:before {
  content: "\f048"; }

.igt-icon-fast-backward:before {
  content: "\f049"; }

.igt-icon-backward:before {
  content: "\f04a"; }

.igt-icon-play:before {
  content: "\f04b"; }

.igt-icon-pause:before {
  content: "\f04c"; }

.igt-icon-stop:before {
  content: "\f04d"; }

.igt-icon-forward:before {
  content: "\f04e"; }

.igt-icon-fast-forward:before {
  content: "\f050"; }

.igt-icon-step-forward:before {
  content: "\f051"; }

.igt-icon-eject:before {
  content: "\f052"; }

.igt-icon-chevron-left:before {
  content: "\f053"; }

.igt-icon-chevron-right:before {
  content: "\f054"; }

.igt-icon-plus-circle:before {
  content: "\f055"; }

.igt-icon-minus-circle:before {
  content: "\f056"; }

.igt-icon-times-circle:before {
  content: "\f057"; }

.igt-icon-check-circle:before {
  content: "\f058"; }

.igt-icon-question-circle:before {
  content: "\f059"; }

.igt-icon-info-circle:before {
  content: "\f05a"; }

.igt-icon-crosshairs:before {
  content: "\f05b"; }

.igt-icon-times-circle-o:before {
  content: "\f05c"; }

.igt-icon-check-circle-o:before {
  content: "\f05d"; }

.igt-icon-ban:before {
  content: "\f05e"; }

.igt-icon-arrow-left:before {
  content: "\f060"; }

.igt-icon-arrow-right:before {
  content: "\f061"; }

.igt-icon-arrow-up:before {
  content: "\f062"; }

.igt-icon-arrow-down:before {
  content: "\f063"; }

.igt-icon-mail-forward:before,
.igt-icon-share:before {
  content: "\f064"; }

.igt-icon-expand:before {
  content: "\f065"; }

.igt-icon-compress:before {
  content: "\f066"; }

.igt-icon-plus:before {
  content: "\f067"; }

.igt-icon-minus:before {
  content: "\f068"; }

.igt-icon-asterisk:before {
  content: "\f069"; }

.igt-icon-exclamation-circle:before {
  content: "\f06a"; }

.igt-icon-gift:before {
  content: "\f06b"; }

.igt-icon-leaf:before {
  content: "\f06c"; }

.igt-icon-fire:before {
  content: "\f06d"; }

.igt-icon-eye:before {
  content: "\f06e"; }

.igt-icon-eye-slash:before {
  content: "\f070"; }

.igt-icon-warning:before,
.igt-icon-exclamation-triangle:before {
  content: "\f071"; }

.igt-icon-plane:before {
  content: "\f072"; }

.igt-icon-calendar:before {
  content: "\f073"; }

.igt-icon-random:before {
  content: "\f074"; }

.igt-icon-comment:before {
  content: "\f075"; }

.igt-icon-magnet:before {
  content: "\f076"; }

.igt-icon-chevron-up:before {
  content: "\f077"; }

.igt-icon-chevron-down:before {
  content: "\f078"; }

.igt-icon-retweet:before {
  content: "\f079"; }

.igt-icon-shopping-cart:before {
  content: "\f07a"; }

.igt-icon-folder:before {
  content: "\f07b"; }

.igt-icon-folder-open:before {
  content: "\f07c"; }

.igt-icon-arrows-v:before {
  content: "\f07d"; }

.igt-icon-arrows-h:before {
  content: "\f07e"; }

.igt-icon-bar-chart-o:before,
.igt-icon-bar-chart:before {
  content: "\f080"; }

.igt-icon-twitter-square:before {
  content: "\f081"; }

.igt-icon-facebook-square:before {
  content: "\f082"; }

.igt-icon-camera-retro:before {
  content: "\f083"; }

.igt-icon-key:before {
  content: "\f084"; }

.igt-icon-gears:before,
.igt-icon-cogs:before {
  content: "\f085"; }

.igt-icon-comments:before {
  content: "\f086"; }

.igt-icon-thumbs-o-up:before {
  content: "\f087"; }

.igt-icon-thumbs-o-down:before {
  content: "\f088"; }

.igt-icon-star-half:before {
  content: "\f089"; }

.igt-icon-heart-o:before {
  content: "\f08a"; }

.igt-icon-sign-out:before {
  content: "\f08b"; }

.igt-icon-linkedin-square:before {
  content: "\f08c"; }

.igt-icon-thumb-tack:before {
  content: "\f08d"; }

.igt-icon-external-link:before {
  content: "\f08e"; }

.igt-icon-sign-in:before {
  content: "\f090"; }

.igt-icon-trophy:before {
  content: "\f091"; }

.igt-icon-github-square:before {
  content: "\f092"; }

.igt-icon-upload:before {
  content: "\f093"; }

.igt-icon-lemon-o:before {
  content: "\f094"; }

.igt-icon-phone:before {
  content: "\f095"; }

.igt-icon-square-o:before {
  content: "\f096"; }

.igt-icon-bookmark-o:before {
  content: "\f097"; }

.igt-icon-phone-square:before {
  content: "\f098"; }

.igt-icon-twitter:before {
  content: "\f099"; }

.igt-icon-facebook-f:before,
.igt-icon-facebook:before {
  content: "\f09a"; }

.igt-icon-github:before {
  content: "\f09b"; }

.igt-icon-unlock:before {
  content: "\f09c"; }

.igt-icon-credit-card:before {
  content: "\f09d"; }

.igt-icon-rss:before {
  content: "\f09e"; }

.igt-icon-hdd-o:before {
  content: "\f0a0"; }

.igt-icon-bullhorn:before {
  content: "\f0a1"; }

.igt-icon-bell:before {
  content: "\f0f3"; }

.igt-icon-certificate:before {
  content: "\f0a3"; }

.igt-icon-hand-o-right:before {
  content: "\f0a4"; }

.igt-icon-hand-o-left:before {
  content: "\f0a5"; }

.igt-icon-hand-o-up:before {
  content: "\f0a6"; }

.igt-icon-hand-o-down:before {
  content: "\f0a7"; }

.igt-icon-arrow-circle-left:before {
  content: "\f0a8"; }

.igt-icon-arrow-circle-right:before {
  content: "\f0a9"; }

.igt-icon-arrow-circle-up:before {
  content: "\f0aa"; }

.igt-icon-arrow-circle-down:before {
  content: "\f0ab"; }

.igt-icon-globe:before {
  content: "\f0ac"; }

.igt-icon-wrench:before {
  content: "\f0ad"; }

.igt-icon-tasks:before {
  content: "\f0ae"; }

.igt-icon-filter:before {
  content: "\f0b0"; }

.igt-icon-briefcase:before {
  content: "\f0b1"; }

.igt-icon-arrows-alt:before {
  content: "\f0b2"; }

.igt-icon-group:before,
.igt-icon-users:before {
  content: "\f0c0"; }

.igt-icon-chain:before,
.igt-icon-link:before {
  content: "\f0c1"; }

.igt-icon-cloud:before {
  content: "\f0c2"; }

.igt-icon-flask:before {
  content: "\f0c3"; }

.igt-icon-cut:before,
.igt-icon-scissors:before {
  content: "\f0c4"; }

.igt-icon-copy:before,
.igt-icon-files-o:before {
  content: "\f0c5"; }

.igt-icon-paperclip:before {
  content: "\f0c6"; }

.igt-icon-save:before,
.igt-icon-floppy-o:before {
  content: "\f0c7"; }

.igt-icon-square:before {
  content: "\f0c8"; }

.igt-icon-navicon:before,
.igt-icon-reorder:before,
.igt-icon-bars:before {
  content: "\f0c9"; }

.igt-icon-list-ul:before {
  content: "\f0ca"; }

.igt-icon-list-ol:before {
  content: "\f0cb"; }

.igt-icon-strikethrough:before {
  content: "\f0cc"; }

.igt-icon-underline:before {
  content: "\f0cd"; }

.igt-icon-table:before {
  content: "\f0ce"; }

.igt-icon-magic:before {
  content: "\f0d0"; }

.igt-icon-truck:before {
  content: "\f0d1"; }

.igt-icon-pinterest:before {
  content: "\f0d2"; }

.igt-icon-pinterest-square:before {
  content: "\f0d3"; }

.igt-icon-google-plus-square:before {
  content: "\f0d4"; }

.igt-icon-google-plus:before {
  content: "\f0d5"; }

.igt-icon-money:before {
  content: "\f0d6"; }

.igt-icon-caret-down:before {
  content: "\f0d7"; }

.igt-icon-caret-up:before {
  content: "\f0d8"; }

.igt-icon-caret-left:before {
  content: "\f0d9"; }

.igt-icon-caret-right:before {
  content: "\f0da"; }

.igt-icon-columns:before {
  content: "\f0db"; }

.igt-icon-unsorted:before,
.igt-icon-sort:before {
  content: "\f0dc"; }

.igt-icon-sort-down:before,
.igt-icon-sort-desc:before {
  content: "\f0dd"; }

.igt-icon-sort-up:before,
.igt-icon-sort-asc:before {
  content: "\f0de"; }

.igt-icon-envelope:before {
  content: "\f0e0"; }

.igt-icon-linkedin:before {
  content: "\f0e1"; }

.igt-icon-rotate-left:before,
.igt-icon-undo:before {
  content: "\f0e2"; }

.igt-icon-legal:before,
.igt-icon-gavel:before {
  content: "\f0e3"; }

.igt-icon-dashboard:before,
.igt-icon-tachometer:before {
  content: "\f0e4"; }

.igt-icon-comment-o:before {
  content: "\f0e5"; }

.igt-icon-comments-o:before {
  content: "\f0e6"; }

.igt-icon-flash:before,
.igt-icon-bolt:before {
  content: "\f0e7"; }

.igt-icon-sitemap:before {
  content: "\f0e8"; }

.igt-icon-umbrella:before {
  content: "\f0e9"; }

.igt-icon-paste:before,
.igt-icon-clipboard:before {
  content: "\f0ea"; }

.igt-icon-lightbulb-o:before {
  content: "\f0eb"; }

.igt-icon-exchange:before {
  content: "\f0ec"; }

.igt-icon-cloud-download:before {
  content: "\f0ed"; }

.igt-icon-cloud-upload:before {
  content: "\f0ee"; }

.igt-icon-user-md:before {
  content: "\f0f0"; }

.igt-icon-stethoscope:before {
  content: "\f0f1"; }

.igt-icon-suitcase:before {
  content: "\f0f2"; }

.igt-icon-bell-o:before {
  content: "\f0a2"; }

.igt-icon-coffee:before {
  content: "\f0f4"; }

.igt-icon-cutlery:before {
  content: "\f0f5"; }

.igt-icon-file-text-o:before {
  content: "\f0f6"; }

.igt-icon-building-o:before {
  content: "\f0f7"; }

.igt-icon-hospital-o:before {
  content: "\f0f8"; }

.igt-icon-ambulance:before {
  content: "\f0f9"; }

.igt-icon-medkit:before {
  content: "\f0fa"; }

.igt-icon-fighter-jet:before {
  content: "\f0fb"; }

.igt-icon-beer:before {
  content: "\f0fc"; }

.igt-icon-h-square:before {
  content: "\f0fd"; }

.igt-icon-plus-square:before {
  content: "\f0fe"; }

.igt-icon-angle-double-left:before {
  content: "\f100"; }

.igt-icon-angle-double-right:before {
  content: "\f101"; }

.igt-icon-angle-double-up:before {
  content: "\f102"; }

.igt-icon-angle-double-down:before {
  content: "\f103"; }

.igt-icon-angle-left:before {
  content: "\f104"; }

.igt-icon-angle-right:before {
  content: "\f105"; }

.igt-icon-angle-up:before {
  content: "\f106"; }

.igt-icon-angle-down:before {
  content: "\f107"; }

.igt-icon-desktop:before {
  content: "\f108"; }

.igt-icon-laptop:before {
  content: "\f109"; }

.igt-icon-tablet:before {
  content: "\f10a"; }

.igt-icon-mobile-phone:before,
.igt-icon-mobile:before {
  content: "\f10b"; }

.igt-icon-circle-o:before {
  content: "\f10c"; }

.igt-icon-quote-left:before {
  content: "\f10d"; }

.igt-icon-quote-right:before {
  content: "\f10e"; }

.igt-icon-spinner:before {
  content: "\f110"; }

.igt-icon-circle:before {
  content: "\f111"; }

.igt-icon-mail-reply:before,
.igt-icon-reply:before {
  content: "\f112"; }

.igt-icon-github-alt:before {
  content: "\f113"; }

.igt-icon-folder-o:before {
  content: "\f114"; }

.igt-icon-folder-open-o:before {
  content: "\f115"; }

.igt-icon-smile-o:before {
  content: "\f118"; }

.igt-icon-frown-o:before {
  content: "\f119"; }

.igt-icon-meh-o:before {
  content: "\f11a"; }

.igt-icon-gamepad:before {
  content: "\f11b"; }

.igt-icon-keyboard-o:before {
  content: "\f11c"; }

.igt-icon-flag-o:before {
  content: "\f11d"; }

.igt-icon-flag-checkered:before {
  content: "\f11e"; }

.igt-icon-terminal:before {
  content: "\f120"; }

.igt-icon-code:before {
  content: "\f121"; }

.igt-icon-mail-reply-all:before,
.igt-icon-reply-all:before {
  content: "\f122"; }

.igt-icon-star-half-empty:before,
.igt-icon-star-half-full:before,
.igt-icon-star-half-o:before {
  content: "\f123"; }

.igt-icon-location-arrow:before {
  content: "\f124"; }

.igt-icon-crop:before {
  content: "\f125"; }

.igt-icon-code-fork:before {
  content: "\f126"; }

.igt-icon-unlink:before,
.igt-icon-chain-broken:before {
  content: "\f127"; }

.igt-icon-question:before {
  content: "\f128"; }

.igt-icon-info:before {
  content: "\f129"; }

.igt-icon-exclamation:before {
  content: "\f12a"; }

.igt-icon-superscript:before {
  content: "\f12b"; }

.igt-icon-subscript:before {
  content: "\f12c"; }

.igt-icon-eraser:before {
  content: "\f12d"; }

.igt-icon-puzzle-piece:before {
  content: "\f12e"; }

.igt-icon-microphone:before {
  content: "\f130"; }

.igt-icon-microphone-slash:before {
  content: "\f131"; }

.igt-icon-shield:before {
  content: "\f132"; }

.igt-icon-calendar-o:before {
  content: "\f133"; }

.igt-icon-fire-extinguisher:before {
  content: "\f134"; }

.igt-icon-rocket:before {
  content: "\f135"; }

.igt-icon-maxcdn:before {
  content: "\f136"; }

.igt-icon-chevron-circle-left:before {
  content: "\f137"; }

.igt-icon-chevron-circle-right:before {
  content: "\f138"; }

.igt-icon-chevron-circle-up:before {
  content: "\f139"; }

.igt-icon-chevron-circle-down:before {
  content: "\f13a"; }

.igt-icon-html5:before {
  content: "\f13b"; }

.igt-icon-css3:before {
  content: "\f13c"; }

.igt-icon-anchor:before {
  content: "\f13d"; }

.igt-icon-unlock-alt:before {
  content: "\f13e"; }

.igt-icon-bullseye:before {
  content: "\f140"; }

.igt-icon-ellipsis-h:before {
  content: "\f141"; }

.igt-icon-ellipsis-v:before {
  content: "\f142"; }

.igt-icon-rss-square:before {
  content: "\f143"; }

.igt-icon-play-circle:before {
  content: "\f144"; }

.igt-icon-ticket:before {
  content: "\f145"; }

.igt-icon-minus-square:before {
  content: "\f146"; }

.igt-icon-minus-square-o:before {
  content: "\f147"; }

.igt-icon-level-up:before {
  content: "\f148"; }

.igt-icon-level-down:before {
  content: "\f149"; }

.igt-icon-check-square:before {
  content: "\f14a"; }

.igt-icon-pencil-square:before {
  content: "\f14b"; }

.igt-icon-external-link-square:before {
  content: "\f14c"; }

.igt-icon-share-square:before {
  content: "\f14d"; }

.igt-icon-compass:before {
  content: "\f14e"; }

.igt-icon-toggle-down:before,
.igt-icon-caret-square-o-down:before {
  content: "\f150"; }

.igt-icon-toggle-up:before,
.igt-icon-caret-square-o-up:before {
  content: "\f151"; }

.igt-icon-toggle-right:before,
.igt-icon-caret-square-o-right:before {
  content: "\f152"; }

.igt-icon-euro:before,
.igt-icon-eur:before {
  content: "\f153"; }

.igt-icon-gbp:before {
  content: "\f154"; }

.igt-icon-dollar:before,
.igt-icon-usd:before {
  content: "\f155"; }

.igt-icon-rupee:before,
.igt-icon-inr:before {
  content: "\f156"; }

.igt-icon-cny:before,
.igt-icon-rmb:before,
.igt-icon-yen:before,
.igt-icon-jpy:before {
  content: "\f157"; }

.igt-icon-ruble:before,
.igt-icon-rouble:before,
.igt-icon-rub:before {
  content: "\f158"; }

.igt-icon-won:before,
.igt-icon-krw:before {
  content: "\f159"; }

.igt-icon-bitcoin:before,
.igt-icon-btc:before {
  content: "\f15a"; }

.igt-icon-file:before {
  content: "\f15b"; }

.igt-icon-file-text:before {
  content: "\f15c"; }

.igt-icon-sort-alpha-asc:before {
  content: "\f15d"; }

.igt-icon-sort-alpha-desc:before {
  content: "\f15e"; }

.igt-icon-sort-amount-asc:before {
  content: "\f160"; }

.igt-icon-sort-amount-desc:before {
  content: "\f161"; }

.igt-icon-sort-numeric-asc:before {
  content: "\f162"; }

.igt-icon-sort-numeric-desc:before {
  content: "\f163"; }

.igt-icon-thumbs-up:before {
  content: "\f164"; }

.igt-icon-thumbs-down:before {
  content: "\f165"; }

.igt-icon-youtube-square:before {
  content: "\f166"; }

.igt-icon-youtube:before {
  content: "\f167"; }

.igt-icon-xing:before {
  content: "\f168"; }

.igt-icon-xing-square:before {
  content: "\f169"; }

.igt-icon-youtube-play:before {
  content: "\f16a"; }

.igt-icon-dropbox:before {
  content: "\f16b"; }

.igt-icon-stack-overflow:before {
  content: "\f16c"; }

.igt-icon-instagram:before {
  content: "\f16d"; }

.igt-icon-flickr:before {
  content: "\f16e"; }

.igt-icon-adn:before {
  content: "\f170"; }

.igt-icon-bitbucket:before {
  content: "\f171"; }

.igt-icon-bitbucket-square:before {
  content: "\f172"; }

.igt-icon-tumblr:before {
  content: "\f173"; }

.igt-icon-tumblr-square:before {
  content: "\f174"; }

.igt-icon-long-arrow-down:before {
  content: "\f175"; }

.igt-icon-long-arrow-up:before {
  content: "\f176"; }

.igt-icon-long-arrow-left:before {
  content: "\f177"; }

.igt-icon-long-arrow-right:before {
  content: "\f178"; }

.igt-icon-apple:before {
  content: "\f179"; }

.igt-icon-windows:before {
  content: "\f17a"; }

.igt-icon-android:before {
  content: "\f17b"; }

.igt-icon-linux:before {
  content: "\f17c"; }

.igt-icon-dribbble:before {
  content: "\f17d"; }

.igt-icon-skype:before {
  content: "\f17e"; }

.igt-icon-foursquare:before {
  content: "\f180"; }

.igt-icon-trello:before {
  content: "\f181"; }

.igt-icon-female:before {
  content: "\f182"; }

.igt-icon-male:before {
  content: "\f183"; }

.igt-icon-gittip:before,
.igt-icon-gratipay:before {
  content: "\f184"; }

.igt-icon-sun-o:before {
  content: "\f185"; }

.igt-icon-moon-o:before {
  content: "\f186"; }

.igt-icon-archive:before {
  content: "\f187"; }

.igt-icon-bug:before {
  content: "\f188"; }

.igt-icon-vk:before {
  content: "\f189"; }

.igt-icon-weibo:before {
  content: "\f18a"; }

.igt-icon-renren:before {
  content: "\f18b"; }

.igt-icon-pagelines:before {
  content: "\f18c"; }

.igt-icon-stack-exchange:before {
  content: "\f18d"; }

.igt-icon-arrow-circle-o-right:before {
  content: "\f18e"; }

.igt-icon-arrow-circle-o-left:before {
  content: "\f190"; }

.igt-icon-toggle-left:before,
.igt-icon-caret-square-o-left:before {
  content: "\f191"; }

.igt-icon-dot-circle-o:before {
  content: "\f192"; }

.igt-icon-wheelchair:before {
  content: "\f193"; }

.igt-icon-vimeo-square:before {
  content: "\f194"; }

.igt-icon-turkish-lira:before,
.igt-icon-try:before {
  content: "\f195"; }

.igt-icon-plus-square-o:before {
  content: "\f196"; }

.igt-icon-space-shuttle:before {
  content: "\f197"; }

.igt-icon-slack:before {
  content: "\f198"; }

.igt-icon-envelope-square:before {
  content: "\f199"; }

.igt-icon-wordpress:before {
  content: "\f19a"; }

.igt-icon-openid:before {
  content: "\f19b"; }

.igt-icon-institution:before,
.igt-icon-bank:before,
.igt-icon-university:before {
  content: "\f19c"; }

.igt-icon-mortar-board:before,
.igt-icon-graduation-cap:before {
  content: "\f19d"; }

.igt-icon-yahoo:before {
  content: "\f19e"; }

.igt-icon-google:before {
  content: "\f1a0"; }

.igt-icon-reddit:before {
  content: "\f1a1"; }

.igt-icon-reddit-square:before {
  content: "\f1a2"; }

.igt-icon-stumbleupon-circle:before {
  content: "\f1a3"; }

.igt-icon-stumbleupon:before {
  content: "\f1a4"; }

.igt-icon-delicious:before {
  content: "\f1a5"; }

.igt-icon-digg:before {
  content: "\f1a6"; }

.igt-icon-pied-piper:before {
  content: "\f1a7"; }

.igt-icon-pied-piper-alt:before {
  content: "\f1a8"; }

.igt-icon-drupal:before {
  content: "\f1a9"; }

.igt-icon-joomla:before {
  content: "\f1aa"; }

.igt-icon-language:before {
  content: "\f1ab"; }

.igt-icon-fax:before {
  content: "\f1ac"; }

.igt-icon-building:before {
  content: "\f1ad"; }

.igt-icon-child:before {
  content: "\f1ae"; }

.igt-icon-paw:before {
  content: "\f1b0"; }

.igt-icon-spoon:before {
  content: "\f1b1"; }

.igt-icon-cube:before {
  content: "\f1b2"; }

.igt-icon-cubes:before {
  content: "\f1b3"; }

.igt-icon-behance:before {
  content: "\f1b4"; }

.igt-icon-behance-square:before {
  content: "\f1b5"; }

.igt-icon-steam:before {
  content: "\f1b6"; }

.igt-icon-steam-square:before {
  content: "\f1b7"; }

.igt-icon-recycle:before {
  content: "\f1b8"; }

.igt-icon-automobile:before,
.igt-icon-car:before {
  content: "\f1b9"; }

.igt-icon-cab:before,
.igt-icon-taxi:before {
  content: "\f1ba"; }

.igt-icon-tree:before {
  content: "\f1bb"; }

.igt-icon-spotify:before {
  content: "\f1bc"; }

.igt-icon-deviantart:before {
  content: "\f1bd"; }

.igt-icon-soundcloud:before {
  content: "\f1be"; }

.igt-icon-database:before {
  content: "\f1c0"; }

.igt-icon-file-pdf-o:before {
  content: "\f1c1"; }

.igt-icon-file-word-o:before {
  content: "\f1c2"; }

.igt-icon-file-excel-o:before {
  content: "\f1c3"; }

.igt-icon-file-powerpoint-o:before {
  content: "\f1c4"; }

.igt-icon-file-photo-o:before,
.igt-icon-file-picture-o:before,
.igt-icon-file-image-o:before {
  content: "\f1c5"; }

.igt-icon-file-zip-o:before,
.igt-icon-file-archive-o:before {
  content: "\f1c6"; }

.igt-icon-file-sound-o:before,
.igt-icon-file-audio-o:before {
  content: "\f1c7"; }

.igt-icon-file-movie-o:before,
.igt-icon-file-video-o:before {
  content: "\f1c8"; }

.igt-icon-file-code-o:before {
  content: "\f1c9"; }

.igt-icon-vine:before {
  content: "\f1ca"; }

.igt-icon-codepen:before {
  content: "\f1cb"; }

.igt-icon-jsfiddle:before {
  content: "\f1cc"; }

.igt-icon-life-bouy:before,
.igt-icon-life-buoy:before,
.igt-icon-life-saver:before,
.igt-icon-support:before,
.igt-icon-life-ring:before {
  content: "\f1cd"; }

.igt-icon-circle-o-notch:before {
  content: "\f1ce"; }

.igt-icon-ra:before,
.igt-icon-rebel:before {
  content: "\f1d0"; }

.igt-icon-ge:before,
.igt-icon-empire:before {
  content: "\f1d1"; }

.igt-icon-git-square:before {
  content: "\f1d2"; }

.igt-icon-git:before {
  content: "\f1d3"; }

.igt-icon-hacker-news:before {
  content: "\f1d4"; }

.igt-icon-tencent-weibo:before {
  content: "\f1d5"; }

.igt-icon-qq:before {
  content: "\f1d6"; }

.igt-icon-wechat:before,
.igt-icon-weixin:before {
  content: "\f1d7"; }

.igt-icon-send:before,
.igt-icon-paper-plane:before {
  content: "\f1d8"; }

.igt-icon-send-o:before,
.igt-icon-paper-plane-o:before {
  content: "\f1d9"; }

.igt-icon-history:before {
  content: "\f1da"; }

.igt-icon-genderless:before,
.igt-icon-circle-thin:before {
  content: "\f1db"; }

.igt-icon-header:before {
  content: "\f1dc"; }

.igt-icon-paragraph:before {
  content: "\f1dd"; }

.igt-icon-sliders:before {
  content: "\f1de"; }

.igt-icon-share-alt:before {
  content: "\f1e0"; }

.igt-icon-share-alt-square:before {
  content: "\f1e1"; }

.igt-icon-bomb:before {
  content: "\f1e2"; }

.igt-icon-soccer-ball-o:before,
.igt-icon-futbol-o:before {
  content: "\f1e3"; }

.igt-icon-tty:before {
  content: "\f1e4"; }

.igt-icon-binoculars:before {
  content: "\f1e5"; }

.igt-icon-plug:before {
  content: "\f1e6"; }

.igt-icon-slideshare:before {
  content: "\f1e7"; }

.igt-icon-twitch:before {
  content: "\f1e8"; }

.igt-icon-yelp:before {
  content: "\f1e9"; }

.igt-icon-newspaper-o:before {
  content: "\f1ea"; }

.igt-icon-wifi:before {
  content: "\f1eb"; }

.igt-icon-calculator:before {
  content: "\f1ec"; }

.igt-icon-paypal:before {
  content: "\f1ed"; }

.igt-icon-google-wallet:before {
  content: "\f1ee"; }

.igt-icon-cc-visa:before {
  content: "\f1f0"; }

.igt-icon-cc-mastercard:before {
  content: "\f1f1"; }

.igt-icon-cc-discover:before {
  content: "\f1f2"; }

.igt-icon-cc-amex:before {
  content: "\f1f3"; }

.igt-icon-cc-paypal:before {
  content: "\f1f4"; }

.igt-icon-cc-stripe:before {
  content: "\f1f5"; }

.igt-icon-bell-slash:before {
  content: "\f1f6"; }

.igt-icon-bell-slash-o:before {
  content: "\f1f7"; }

.igt-icon-trash:before {
  content: "\f1f8"; }

.igt-icon-copyright:before {
  content: "\f1f9"; }

.igt-icon-at:before {
  content: "\f1fa"; }

.igt-icon-eyedropper:before {
  content: "\f1fb"; }

.igt-icon-paint-brush:before {
  content: "\f1fc"; }

.igt-icon-birthday-cake:before {
  content: "\f1fd"; }

.igt-icon-area-chart:before {
  content: "\f1fe"; }

.igt-icon-pie-chart:before {
  content: "\f200"; }

.igt-icon-line-chart:before {
  content: "\f201"; }

.igt-icon-lastfm:before {
  content: "\f202"; }

.igt-icon-lastfm-square:before {
  content: "\f203"; }

.igt-icon-toggle-off:before {
  content: "\f204"; }

.igt-icon-toggle-on:before {
  content: "\f205"; }

.igt-icon-bicycle:before {
  content: "\f206"; }

.igt-icon-bus:before {
  content: "\f207"; }

.igt-icon-ioxhost:before {
  content: "\f208"; }

.igt-icon-angellist:before {
  content: "\f209"; }

.igt-icon-cc:before {
  content: "\f20a"; }

.igt-icon-shekel:before,
.igt-icon-sheqel:before,
.igt-icon-ils:before {
  content: "\f20b"; }

.igt-icon-meanpath:before {
  content: "\f20c"; }

.igt-icon-buysellads:before {
  content: "\f20d"; }

.igt-icon-connectdevelop:before {
  content: "\f20e"; }

.igt-icon-dashcube:before {
  content: "\f210"; }

.igt-icon-forumbee:before {
  content: "\f211"; }

.igt-icon-leanpub:before {
  content: "\f212"; }

.igt-icon-sellsy:before {
  content: "\f213"; }

.igt-icon-shirtsinbulk:before {
  content: "\f214"; }

.igt-icon-simplybuilt:before {
  content: "\f215"; }

.igt-icon-skyatlas:before {
  content: "\f216"; }

.igt-icon-cart-plus:before {
  content: "\f217"; }

.igt-icon-cart-arrow-down:before {
  content: "\f218"; }

.igt-icon-diamond:before {
  content: "\f219"; }

.igt-icon-ship:before {
  content: "\f21a"; }

.igt-icon-user-secret:before {
  content: "\f21b"; }

.igt-icon-motorcycle:before {
  content: "\f21c"; }

.igt-icon-street-view:before {
  content: "\f21d"; }

.igt-icon-heartbeat:before {
  content: "\f21e"; }

.igt-icon-venus:before {
  content: "\f221"; }

.igt-icon-mars:before {
  content: "\f222"; }

.igt-icon-mercury:before {
  content: "\f223"; }

.igt-icon-transgender:before {
  content: "\f224"; }

.igt-icon-transgender-alt:before {
  content: "\f225"; }

.igt-icon-venus-double:before {
  content: "\f226"; }

.igt-icon-mars-double:before {
  content: "\f227"; }

.igt-icon-venus-mars:before {
  content: "\f228"; }

.igt-icon-mars-stroke:before {
  content: "\f229"; }

.igt-icon-mars-stroke-v:before {
  content: "\f22a"; }

.igt-icon-mars-stroke-h:before {
  content: "\f22b"; }

.igt-icon-neuter:before {
  content: "\f22c"; }

.igt-icon-facebook-official:before {
  content: "\f230"; }

.igt-icon-pinterest-p:before {
  content: "\f231"; }

.igt-icon-whatsapp:before {
  content: "\f232"; }

.igt-icon-server:before {
  content: "\f233"; }

.igt-icon-user-plus:before {
  content: "\f234"; }

.igt-icon-user-times:before {
  content: "\f235"; }

.igt-icon-hotel:before,
.igt-icon-bed:before {
  content: "\f236"; }

.igt-icon-viacoin:before {
  content: "\f237"; }

.igt-icon-train:before {
  content: "\f238"; }

.igt-icon-subway:before {
  content: "\f239"; }

.igt-icon-medium-logo:before {
  content: "\f23a"; }

/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Removes inner padding and border in Firefox 4+.
 */
.igt-close::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * 1. Correct inability to style clickable `input` types in iOS.
 * 2. Remove margins in Chrome, Safari and Opera.
 * 3. Remove borders for `button`.
 * 4. Address `overflow` set to `hidden` in IE 8/9/10/11.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Address inconsistent `text-transform` inheritance which is only inherit in Firefox and IE
 * 7. Remove default `button` padding and background color
 * 8. Style
 */
.igt-close {
  /* 1 */
  -webkit-appearance: none;
  /* 2 */
  margin: 0;
  /* 3 */
  border: none;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background: transparent;
  /* 8 */
  display: inline-block;
  box-sizing: content-box;
  width: 20px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  opacity: 0.3; }

/* Icon */
.igt-close:after {
  display: block;
  content: "\f00d";
  font-family: FontAwesome; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 * 3. Required for `a` elements
 */
.igt-close:hover,
.igt-close:focus {
  opacity: 0.5;
  /* 2 */
  outline: none;
  /* 3 */
  color: inherit;
  text-decoration: none;
  cursor: pointer; }

/* Modifier
 ========================================================================== */
.igt-close-alt {
  padding: 2px;
  border-radius: 50%;
  background: #eee;
  opacity: 1; }

/* Hover */
.igt-close-alt:hover,
.igt-close-alt:focus {
  opacity: 1; }

/* Icon */
.igt-close-alt:after {
  opacity: 0.5; }

.igt-close-alt:hover:after,
.igt-close-alt:focus:after {
  opacity: 0.8; }

/* ========================================================================
   Component: Badge
 ========================================================================== */
.igt-badge {
  display: inline-block;
  padding: 0 5px;
  background: #39a448;
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  text-transform: none; }

/*
 * Keep color when badge is a link
 */
a.igt-badge:hover {
  color: #fff; }

/* Modifier: `igt-badge-notification`;
 ========================================================================== */
.igt-badge-notification {
  box-sizing: border-box;
  min-width: 18px;
  border-radius: 500px;
  font-size: 12px;
  line-height: 18px; }

/* Color modifier
 ========================================================================== */
/*
 * Modifier: `igt-badge-success`
 */
.igt-badge-success {
  background-color: #8cc14c; }

/*
 * Modifier: `igt-badge-warning`
 */
.igt-badge-warning {
  background-color: #faa732; }

/*
 * Modifier: `igt-badge-danger`
 */
.igt-badge-danger {
  background-color: #da314b; }

/* ========================================================================
   Component: Alert
 ========================================================================== */
.igt-alert {
  margin-bottom: 15px;
  padding: 10px;
  background: #d9edf7;
  color: #31708f;
  border-radius: 3px; }

/*
 * Add margin if adjacent element
 */
* + .igt-alert {
  margin-top: 15px; }

/*
 * Remove margin from the last-child
 */
.igt-alert > :last-child {
  margin-bottom: 0; }

/*
 * Keep color for headings if the default heading color is changed
 */
.igt-alert h1,
.igt-alert h2,
.igt-alert h3,
.igt-alert h4,
.igt-alert h5,
.igt-alert h6 {
  color: inherit; }

/* Close in alert
 ========================================================================== */
.igt-alert > .igt-close:first-child {
  float: right; }

/*
 * Remove margin from adjacent element
 */
.igt-alert > .igt-close:first-child + * {
  margin-top: 0; }

/* Modifier: `igt-alert-success`
 ========================================================================== */
.igt-alert-success {
  background: #dff0d8;
  color: #3c763d; }

/* Modifier: `igt-alert-warning`
 ========================================================================== */
.igt-alert-warning {
  background: #fcf8e3;
  color: #8a6d3b; }

/* Modifier: `igt-alert-danger`
 ========================================================================== */
.igt-alert-danger {
  background: #f2dede;
  color: #a94442; }

/* Modifier: `igt-alert-large`
 ========================================================================== */
.igt-alert-large {
  padding: 20px; }

.igt-alert-large > .igt-close:first-child {
  margin: -10px -10px 0 0; }

/* ========================================================================
   Component: Thumbnail
 ========================================================================== */
/*
 * 1. Container width fits its content
 * 2. Responsive behavior
 * 3. Corrects `max-width` behavior sed
 * 4. Required for `figure` element
 * 5. Style
 */
.igt-thumbnail {
  /* 1 */
  display: inline-block;
  /* 2 */
  max-width: 100%;
  /* 3 */
  box-sizing: border-box;
  /* 3 */
  margin: 0;
  /* 4 */
  padding: 4px;
  border: 1px solid #ddd;
  background: #fff; }

/*
 * Hover state for `a` elements
 * 1. Apply hover style also to focus state
 * 2. Needed for caption
 * 3. Remove default focus style
 */
a.igt-thumbnail:hover,
a.igt-thumbnail:focus {
  border-color: #aaa;
  background-color: #fff;
  /* 2 */
  text-decoration: none;
  /* 3 */
  outline: none; }

/* Caption
 ========================================================================== */
.igt-thumbnail-caption {
  padding-top: 4px;
  text-align: center;
  color: #444; }

/* Sizes
 ========================================================================== */
.igt-thumbnail-mini {
  width: 150px; }

.igt-thumbnail-small {
  width: 200px; }

.igt-thumbnail-medium {
  width: 300px; }

.igt-thumbnail-large {
  width: 400px; }

.igt-thumbnail-expand,
.igt-thumbnail-expand > img {
  width: 100%; }

/* ========================================================================
   Component: Overlay
 ========================================================================== */
/*
 * 1. Container width fits its content
 * 2. Create position context
 * 3. Set max-width for responsive images to prevent `inline-block` consequences
 * 4. Remove the gap between the container and its child element
 * 5. Needed for transitions and to fixed wrong scaling calculation for images in Chrome
 * 6. Fixed `overflow: hidden` to be ignored with border-radius and CSS transforms in Webkit
 * 7. Reset margin
 */
.igt-overlay {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  overflow: hidden;
  /* 6 */
  -webkit-transform: translateZ(0);
  /* 7 */
  margin: 0; }

/* 6 for Safari */
.igt-overlay.igt-border-circle {
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%); }

/*
 * Remove margin from content
 */
.igt-overlay > :first-child {
  margin-bottom: 0; }

/* Sub-object `igt-overlay-panel`
 ========================================================================== */
/*
 * 1. Position cover
 * 2. Style
 */
.igt-overlay-panel {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* 2 */
  padding: 20px;
  color: #fff; }

/*
 * Remove margin from the last-child
 */
.igt-overlay-panel > :last-child,
.igt-overlay-panel.igt-flex > * > :last-child {
  margin-bottom: 0; }

/*
 * Keep color for headings if the default heading color is changed
 */
.igt-overlay-panel h1,
.igt-overlay-panel h2,
.igt-overlay-panel h3,
.igt-overlay-panel h4,
.igt-overlay-panel h5,
.igt-overlay-panel h6 {
  color: inherit; }

.igt-overlay-panel a:not([class]) {
  color: inherit;
  text-decoration: underline; }

.igt-overlay-panel a[class*='igt-icon-']:not(.igt-icon-button) {
  color: inherit; }

/* Sub-object `igt-overlay-hover` and `igt-overlay-active`
 ========================================================================== */
.igt-overlay-hover:not(:hover):not(.igt-hover) .igt-overlay-panel:not(.igt-ignore) {
  opacity: 0; }

.igt-overlay-active :not(.igt-active) > .igt-overlay-panel:not(.igt-ignore) {
  opacity: 0; }

/* Modifier `igt-overlay-background`
 ========================================================================== */
.igt-overlay-background {
  background: rgba(0, 0, 0, 0.5); }

/* Modifier `igt-overlay-image`
 ========================================================================== */
/*
 * Reset panel
 */
.igt-overlay-image {
  padding: 0; }

/* Position modifiers
 ========================================================================== */
.igt-overlay-top {
  bottom: auto; }

.igt-overlay-bottom {
  top: auto; }

.igt-overlay-left {
  right: auto; }

.igt-overlay-right {
  left: auto; }

/* Sub-object `igt-overlay-icon`
 ========================================================================== */
.igt-overlay-icon:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  font-size: 50px;
  line-height: 1;
  font-family: FontAwesome;
  text-align: center;
  color: #fff; }

/* Transitions
 ========================================================================== */
.igt-overlay-fade,
.igt-overlay-scale,
.igt-overlay-spin,
.igt-overlay-grayscale,
.igt-overlay-blur,
[class*='igt-overlay-slide'] {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
  -webkit-transition-property: opacity -webkit-transform -webkit-filter, opacity -webkit-transform filter;
          transition-property: opacity transform filter; }

.igt-overlay-active .igt-overlay-fade,
.igt-overlay-active .igt-overlay-scale,
.igt-overlay-active .igt-overlay-spin,
.igt-overlay-active [class*='igt-overlay-slide'] {
  -webkit-transition-duration: 0.8s;
          transition-duration: 0.8s; }

/*
 * Fade
 */
.igt-overlay-fade {
  opacity: 0.7; }

.igt-overlay-hover:hover .igt-overlay-fade,
.igt-overlay-active .igt-active > .igt-overlay-fade {
  opacity: 1; }

/*
 * Scale
 */
.igt-overlay-scale {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
      transform: scale(1); }

.igt-overlay-hover:hover .igt-overlay-scale,
.igt-overlay-active .igt-active > .igt-overlay-scale {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
      transform: scale(1.1); }

/*
 * Spin
 */
.igt-overlay-spin {
  -webkit-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
      transform: scale(1) rotate(0deg); }

.igt-overlay-hover:hover .igt-overlay-spin,
.igt-overlay-active .igt-active > .igt-overlay-spin {
  -webkit-transform: scale(1.1) rotate(3deg);
  -ms-transform: scale(1.1) rotate(3deg);
      transform: scale(1.1) rotate(3deg); }

/*
 * Grayscale
 */
.igt-overlay-grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); }

.igt-overlay-hover:hover .igt-overlay-grayscale,
.igt-overlay-active .igt-active > .igt-overlay-grayscale {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%); }

/*
 * Slide
 */
[class*='igt-overlay-slide'] {
  opacity: 0; }

/* Top */
.igt-overlay-slide-top {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
      transform: translateY(-100%); }

/* Bottom */
.igt-overlay-slide-bottom {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
      transform: translateY(100%); }

/* Left */
.igt-overlay-slide-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
      transform: translateX(-100%); }

/* Right */
.igt-overlay-slide-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
      transform: translateX(100%); }

/* Hover */
.igt-overlay-hover:hover [class*='igt-overlay-slide'],
.igt-overlay-active .igt-active > [class*='igt-overlay-slide'] {
  opacity: 1;
  -webkit-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
      transform: translateX(0) translateY(0); }

/* DEPRECATED
 * Sub-object `igt-overlay-area`
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set style
 * 3. Fade-in transition
 */
.igt-overlay-area {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* 2 */
  background: rgba(0, 0, 0, 0.3);
  /* 3 */
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  -webkit-transform: translate3d(0, 0, 0); }

/*
 * Hover
 * 1. `igt-hover` to support touch devices
 * 2. Use optional `igt-overlay-toggle` to trigger the overlay earlier
 */
.igt-overlay:hover .igt-overlay-area,
.igt-overlay.igt-hover .igt-overlay-area, .igt-overlay-toggle:hover .igt-overlay-area, .igt-overlay-toggle.igt-hover .igt-overlay-area {
  opacity: 1; }

/*
 * Icon
 */
.igt-overlay-area:empty:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  font-size: 50px;
  line-height: 1;
  font-family: FontAwesome;
  text-align: center;
  color: #fff; }

/* DEPRECATED
 * Sub-object `igt-overlay-area-content`
 ========================================================================== */
/*
 * Remove whitespace between child elements when using `inline-block`
 * Needed for Firefox
 */
.igt-overlay-area:not(:empty) {
  font-size: 0.001px; }

/*
 * 1. Needed for vertical alignment
 */
.igt-overlay-area:not(:empty):before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

/*
 * 1. Set vertical alignment
 * 2. Reset whitespace hack
 * 3. Set horizontal alignment
 * 4. Set style
 */
.igt-overlay-area-content {
  /* 1 */
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  vertical-align: middle;
  /* 2 */
  font-size: 1rem;
  /* 3 */
  text-align: center;
  /* 4 */
  padding: 0 15px;
  color: #fff; }

/*
 * Remove margin from the last-child
 */
.igt-overlay-area-content > :last-child {
  margin-bottom: 0; }

/*
 * Links in overlay area
 */
.igt-overlay-area-content a:not([class]),
.igt-overlay-area-content a:not([class]):hover {
  color: inherit; }

/* DEPRECATED
 * Sub-object `igt-overlay-caption`
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set style
 * 3. Fade-in transition
 */
.igt-overlay-caption {
  /* 1 */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* 2 */
  padding: 15px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  /* 3 */
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  -webkit-transform: translate3d(0, 0, 0); }

/*
 * Hover
 * 1. `igt-hover` to support touch devices
 * 2. Use optional `igt-overlay-toggle` to trigger the overlay earlier
 */
.igt-overlay:hover .igt-overlay-caption,
.igt-overlay.igt-hover .igt-overlay-caption, .igt-overlay-toggle:hover .igt-overlay-caption, .igt-overlay-toggle.igt-hover .igt-overlay-caption {
  opacity: 1; }

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*='igt-animation-'] {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

/* Hide animated element if scrollspy is used */
@media screen {
  [data-igt-scrollspy*='igt-animation-']:not([data-igt-scrollspy*='target']) {
    opacity: 0; } }

/*
 * Fade
 * Higher specificity (!important) needed because of reverse modifier
 */
.igt-animation-fade {
  -webkit-animation-name: igt-fade;
  animation-name: igt-fade;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important; }

/*
 * Fade with scale
 */
.igt-animation-scale-up {
  -webkit-animation-name: igt-fade-scale-02;
  animation-name: igt-fade-scale-02; }

.igt-animation-scale-down {
  -webkit-animation-name: igt-fade-scale-18;
  animation-name: igt-fade-scale-18; }

/*
 * Fade with slide
 */
.igt-animation-slide-top {
  -webkit-animation-name: igt-fade-top;
  animation-name: igt-fade-top; }

.igt-animation-slide-bottom {
  -webkit-animation-name: igt-fade-bottom;
  animation-name: igt-fade-bottom; }

.igt-animation-slide-left {
  -webkit-animation-name: igt-fade-left;
  animation-name: igt-fade-left; }

.igt-animation-slide-right {
  -webkit-animation-name: igt-fade-right;
  animation-name: igt-fade-right; }

/*
 * Scale
 */
.igt-animation-scale {
  -webkit-animation-name: igt-scale-12;
  animation-name: igt-scale-12; }

/*
 * Shake
 */
.igt-animation-shake {
  -webkit-animation-name: igt-shake;
  animation-name: igt-shake; }

/* Direction modifiers
 ========================================================================== */
.igt-animation-reverse {
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/* Duration modifiers
========================================================================== */
.igt-animation-15 {
  -webkit-animation-duration: 15s;
  animation-duration: 15s; }

/* Origin modifiers
========================================================================== */
.igt-animation-top-left {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
      transform-origin: 0 0; }

.igt-animation-top-center {
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
      transform-origin: 50% 0; }

.igt-animation-top-right {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
      transform-origin: 100% 0; }

.igt-animation-middle-left {
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
      transform-origin: 0 50%; }

.igt-animation-middle-right {
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
      transform-origin: 100% 50%; }

.igt-animation-bottom-left {
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
      transform-origin: 0 100%; }

.igt-animation-bottom-center {
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
      transform-origin: 50% 100%; }

.igt-animation-bottom-right {
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
      transform-origin: 100% 100%; }

/* Sub-object: `igt-animation-hover`
========================================================================== */
/*
 * Enable animation only on hover
 * Note: Firefox also needs this because animations are not triggered when switching between display `hidden` and `block`
 */
.igt-animation-hover:not(:hover),
.igt-animation-hover:not(:hover) [class*='igt-animation-'],
.igt-touch .igt-animation-hover:not(.igt-hover),
.igt-touch .igt-animation-hover:not(.igt-hover) [class*='igt-animation-'] {
  -webkit-animation-name: none;
  animation-name: none; }

/* Keyframes: Fade
 * Used by dropdown, datepicker and slideshow component
 ========================================================================== */
@-webkit-keyframes igt-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes igt-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Keyframes: Fade with slide
 ========================================================================== */
/*
 * Top
 */
@-webkit-keyframes igt-fade-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes igt-fade-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

/*
 * Bottom
 */
@-webkit-keyframes igt-fade-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes igt-fade-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

/*
 * Left
 */
@-webkit-keyframes igt-fade-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes igt-fade-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

/*
 * Right
 */
@-webkit-keyframes igt-fade-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes igt-fade-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

/* Keyframes: Fade with scale
 ========================================================================== */
/*
 * Scale by 0.2
 */
@-webkit-keyframes igt-fade-scale-02 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1); } }

@keyframes igt-fade-scale-02 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

/*
 * Scale by 1.5
 * Used by slideshow component
 */
@-webkit-keyframes igt-fade-scale-15 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1); } }

@keyframes igt-fade-scale-15 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

/*
 * Scale by 1.8
 */
@-webkit-keyframes igt-fade-scale-18 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.8); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1); } }

@keyframes igt-fade-scale-18 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.8);
            transform: scale(1.8); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

/* Keyframes: Slide
 * Used by slideshow component
 ========================================================================== */
/*
 * Left
 */
@-webkit-keyframes igt-slide-left {
  0% {
    -webkit-transform: translateX(-100%); }
  100% {
    -webkit-transform: translateX(0); } }

@keyframes igt-slide-left {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

/*
 * Right
 */
@-webkit-keyframes igt-slide-right {
  0% {
    -webkit-transform: translateX(100%); }
  100% {
    -webkit-transform: translateX(0); } }

@keyframes igt-slide-right {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

/*
 * Left third
 */
@-webkit-keyframes igt-slide-left-33 {
  0% {
    -webkit-transform: translateX(33%); }
  100% {
    -webkit-transform: translateX(0); } }

@keyframes igt-slide-left-33 {
  0% {
    -webkit-transform: translateX(33%);
            transform: translateX(33%); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

/*
 * Right third
 */
@-webkit-keyframes igt-slide-right-33 {
  0% {
    -webkit-transform: translateX(-33%); }
  100% {
    -webkit-transform: translateX(0); } }

@keyframes igt-slide-right-33 {
  0% {
    -webkit-transform: translateX(-33%);
            transform: translateX(-33%); }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

/* Keyframes: Scale
 ========================================================================== */
@-webkit-keyframes igt-scale-12 {
  0% {
    -webkit-transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1); } }

@keyframes igt-scale-12 {
  0% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

/* Keyframes: Rotate
 * Used by icon component
 ========================================================================== */
@-webkit-keyframes igt-rotate {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg); } }

@keyframes igt-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg); } }

/* Keyframes: Shake
 ========================================================================== */
@-webkit-keyframes igt-shake {
  0%, 100% {
    -webkit-transform: translateX(0); }
  10% {
    -webkit-transform: translateX(-9px); }
  20% {
    -webkit-transform: translateX(8px); }
  30% {
    -webkit-transform: translateX(-7px); }
  40% {
    -webkit-transform: translateX(6px); }
  50% {
    -webkit-transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(4px); }
  70% {
    -webkit-transform: translateX(-3px); }
  80% {
    -webkit-transform: translateX(2px); }
  90% {
    -webkit-transform: translateX(-1px); } }

@keyframes igt-shake {
  0%, 100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  10% {
    -webkit-transform: translateX(-9px);
            transform: translateX(-9px); }
  20% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px); }
  30% {
    -webkit-transform: translateX(-7px);
            transform: translateX(-7px); }
  40% {
    -webkit-transform: translateX(6px);
            transform: translateX(6px); }
  50% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  60% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px); }
  70% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px); }
  80% {
    -webkit-transform: translateX(2px);
            transform: translateX(2px); }
  90% {
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px); } }

/* Keyframes: Fade with slide fixed
 * Used by dropdown and search component
 ========================================================================== */
/*
 * Top fixed
 */
@-webkit-keyframes igt-slide-top-fixed {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes igt-slide-top-fixed {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

/*
 * Bottom fixed
 */
@-webkit-keyframes igt-slide-bottom-fixed {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes igt-slide-bottom-fixed {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Box-sizing is needed for `igt-dropdown-justify`
 * 4. Set style
 * 5. Reset button group whitespace hack
 */
.igt-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 970;
  /* 3 */
  box-sizing: border-box;
  /* 4 */
  width: 200px;
  margin-top: 5px;
  padding: 15px;
  background: #fff;
  color: #444;
  /* 5 */
  font-size: 1rem;
  vertical-align: top;
  box-shadow: 0 2px 2px rgba(68, 68, 68, 0.5);
  border-radius: 5px; }

/*
 * 1. Show dropdown
 * 2. Set animation
 * 3. Needed for scale animation
 */
.igt-open > .igt-dropdown {
  /* 1 */
  display: block;
  /* 2 */
  -webkit-animation: igt-fade 0.2s ease-in-out;
  animation: igt-fade 0.2s ease-in-out;
  /* 3 */
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
      transform-origin: 0 0; }

/* Alignment modifiers
 ========================================================================== */
/*
 * Modifier `igt-dropdown-flip`
 */
.igt-dropdown-flip {
  left: auto;
  right: 0; }

/*
 * Modifier `igt-dropdown-up`
 */
.igt-dropdown-up {
  top: auto;
  bottom: 100%;
  margin-top: auto;
  margin-bottom: 5px; }

/* Nav in dropdown
 ========================================================================== */
.igt-dropdown .igt-nav {
  margin: 0 -15px; }

/* Grid and panel in dropdown
 ========================================================================== */
/*
* Vertical gutter
*/
/*
 * Grid
 * Higher specificity to override large gutter
 */
.igt-grid .igt-dropdown-grid + .igt-dropdown-grid {
  margin-top: 15px; }

/* Panels */
.igt-dropdown-grid > [class*='igt-width-'] > .igt-panel + .igt-panel {
  margin-top: 15px; }

/* Tablet and bigger */
@media (min-width: 768px) {
  /*
     * Horizontal gutter
     */
  .igt-dropdown:not(.igt-dropdown-stack) > .igt-dropdown-grid {
    margin-left: -15px;
    margin-right: -15px; }
  .igt-dropdown:not(.igt-dropdown-stack) > .igt-dropdown-grid > [class*='igt-width-'] {
    padding-left: 15px;
    padding-right: 15px; }
  /*
     * Column divider
     */
  .igt-dropdown:not(.igt-dropdown-stack) > .igt-dropdown-grid > [class*='igt-width-']:nth-child(n+2) {
    border-left: 1px solid #ddd; }
  /*
     * Width multiplier for dropdown columns
     */
  .igt-dropdown-width-2:not(.igt-dropdown-stack) {
    width: 400px; }
  .igt-dropdown-width-3:not(.igt-dropdown-stack) {
    width: 600px; }
  .igt-dropdown-width-4:not(.igt-dropdown-stack) {
    width: 800px; }
  .igt-dropdown-width-5:not(.igt-dropdown-stack) {
    width: 1000px; } }

/* Phone landscape and smaller */
@media (max-width: 767px) {
  /*
     * Stack columns and take full width
     */
  .igt-dropdown-grid > [class*='igt-width-'] {
    width: 100%; }
  /*
     * Vertical gutter
     */
  .igt-dropdown-grid > [class*='igt-width-']:nth-child(n+2) {
    margin-top: 15px; } }

/*
* Stack grid columns
*/
.igt-dropdown-stack > .igt-dropdown-grid > [class*='igt-width-'] {
  width: 100%; }

.igt-dropdown-stack > .igt-dropdown-grid > [class*='igt-width-']:nth-child(n+2) {
  margin-top: 15px; }

/* Modifier `igt-dropdown-small`
 ========================================================================== */
/*
 * Set min-width and text expands dropdown if needed
 */
.igt-dropdown-small {
  min-width: 150px;
  width: auto;
  padding: 5px;
  white-space: nowrap; }

/*
 * Nav in dropdown
 */
.igt-dropdown-small .igt-nav {
  margin: 0 -5px; }

/* Modifier: `igt-dropdown-navbar`
 ========================================================================== */
.igt-dropdown-navbar {
  margin-top: 0;
  background: #fff;
  color: #444; }

.igt-open > .igt-dropdown-navbar {
  -webkit-animation: igt-slide-top-fixed 0.2s ease-in-out;
  animation: igt-slide-top-fixed 0.2s ease-in-out; }

/* Modifier `igt-dropdown-scrollable`
 ========================================================================== */
/*
 * Usefull for long lists
 */
.igt-dropdown-scrollable {
  overflow-y: auto;
  max-height: 200px; }

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * This is the modal overlay and modal dialog container
 * 1. Hide by default
 * 2. Set fixed position
 * 3. Allow scrolling for the modal dialog
 * 4. Mask the background page
 * 5. Fade-in transition
 * 6. Deactivate browser history navigation in IE11
 * 7. force hardware acceleration to prevent browser rendering hiccups
 */
.igt-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  background: rgba(0, 0, 0, 0.6);
  /* 5 */
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  /* 6 */
  -ms-touch-action: cross-slide-y pinch-zoom double-tap-zoom;
      touch-action: cross-slide-y pinch-zoom double-tap-zoom;
  /* 7 */
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

/*
 * Open state
 */
.igt-modal.igt-open {
  opacity: 1; }

/*
 * Prevents duplicated scrollbar caused by 4.
 */
.igt-modal-page,
.igt-modal-page body {
  overflow: hidden; }

/* Sub-object: `igt-modal-dialog`
 ========================================================================== */
/*
 * 1. Create position context for caption, spinner and close button
 * 2. Set box sizing
 * 3. Set style
 * 4. Slide-in transition
 */
.igt-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 50px auto;
  padding: 20px;
  width: 600px;
  max-width: 100%;
  max-width: calc(100% - 20px);
  /* 3 */
  background: #fff;
  /* 4 */
  opacity: 0;
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
      transform: translateY(-100px);
  -webkit-transition: opacity 0.3s linear, -webkit-transform 0.3s ease-out;
  transition: opacity 0.3s linear, transform 0.3s ease-out; }

/* Phone landscape and smaller */
@media (max-width: 767px) {
  /*
     * Fit in small screen
     */
  .igt-modal-dialog {
    width: auto;
    margin: 10px auto; } }

/*
 * Open state
 */
.igt-open .igt-modal-dialog {
  /* 4 */
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
      transform: translateY(0); }

/*
 * Remove margin from the last-child
 */
.igt-modal-dialog > :not([class*='igt-modal-']):last-child {
  margin-bottom: 0; }

/* Close in modal
 ========================================================================== */
.igt-modal-dialog > .igt-close:first-child {
  margin: -10px -10px 0 0;
  float: right; }

/*
 * Remove margin from adjacent element
 */
.igt-modal-dialog > .igt-close:first-child + :not([class*='igt-modal-']) {
  margin-top: 0; }

/* Modifier: `igt-modal-dialog-lightbox`
 ========================================================================== */
.igt-modal-dialog-lightbox {
  margin: 15px auto;
  padding: 0;
  max-width: 95%;
  max-width: calc(100% - 30px); }

/*
 * Close button
 */
.igt-modal-dialog-lightbox > .igt-close:first-child {
  position: absolute;
  top: -12px;
  right: -12px;
  margin: 0;
  float: none; }

/* Phone landscape and smaller */
@media (max-width: 767px) {
  .igt-modal-dialog-lightbox > .igt-close:first-child {
    top: -7px;
    right: -7px; } }

/* Modifier: `igt-modal-dialog-large`
 ========================================================================== */
/* Tablet and bigger */
@media (min-width: 768px) {
  .igt-modal-dialog-large {
    width: 930px; } }

/* Large screen and bigger */
@media (min-width: 1220px) {
  .igt-modal-dialog-large {
    width: 1130px; } }

/* Sub-Object: `igt-modal-header` and `igt-modal-footer`
 ========================================================================== */
.igt-modal-header {
  margin-bottom: 15px; }

.igt-modal-footer {
  margin-top: 15px; }

/*
 * Remove margin from the last-child
 */
.igt-modal-header > :last-child,
.igt-modal-footer > :last-child {
  margin-bottom: 0; }

/* Sub-Object: `igt-modal-caption`
 ========================================================================== */
.igt-modal-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  margin-bottom: -10px;
  color: #fff;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* Sub-Object: `igt-modal-spinner`
 ========================================================================== */
.igt-modal-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  font-size: 25px;
  color: #ddd; }

.igt-modal-spinner:after {
  content: "\f110";
  font-family: FontAwesome;
  -webkit-animation: igt-rotate 2s infinite linear;
  animation: igt-rotate 2s infinite linear; }

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * This is the offcanvas overlay and bar container
 * 1. Hide by default
 * 2. Set fixed position
 * 3. Deactivate browser touch actions in IE11
 * 4. Mask the background page
 */
.igt-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  /* 3 */
  -ms-touch-action: none;
      touch-action: none;
  /* 4 */
  background: rgba(0, 0, 0, 0.1); }

.igt-offcanvas.igt-active {
  display: block; }

/* Sub-object `igt-offcanvas-page`
 ========================================================================== */
/*
 * Prepares the whole HTML page to slide-out
 * 1. Fix the main page and disallow scrolling
 * 2. Side-out transition
 */
.igt-offcanvas-page {
  /* 1 */
  position: fixed;
  /* 2 */
  -webkit-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out; }

/* Sub-object `igt-offcanvas-bar`
 ========================================================================== */
/*
 * This is the offcanvas bar
 * 1. Set fixed position
 * 2. Size and style
 * 3. Allow scrolling
 * 4. Side-out transition
 * 5. Deavtivate scroll chaining in IE11
 */
.igt-offcanvas-bar {
  /* 1 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
  z-index: 1001;
  /* 2 */
  width: 270px;
  max-width: 100%;
  background: #fff;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  /* 5 */
  -ms-scroll-chaining: none; }

.igt-offcanvas.igt-active .igt-offcanvas-bar.igt-offcanvas-bar-show {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
      transform: translateX(0%); }

/* Modifier `igt-offcanvas-bar-flip`
 ========================================================================== */
.igt-offcanvas-bar-flip {
  left: auto;
  right: 0;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
      transform: translateX(100%); }

/* Panel in offcanvas
 ========================================================================== */
.igt-offcanvas .igt-panel {
  margin: 20px 15px;
  color: #444; }

.igt-offcanvas .igt-panel-title {
  color: #444; }

.igt-offcanvas .igt-panel a:not([class]) {
  color: #444; }

.igt-offcanvas .igt-panel a:not([class]):hover {
  color: #333; }

/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * 1. Deactivate browser history navigation in IE11
 */
.igt-switcher {
  margin: 0;
  padding: 0;
  list-style: none;
  /* 1 */
  -ms-touch-action: cross-slide-y pinch-zoom double-tap-zoom;
      touch-action: cross-slide-y pinch-zoom double-tap-zoom; }

/*
 * Items
 */
.igt-switcher > :not(.igt-active) {
  display: none; }

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Size modifiers
 ========================================================================== */
.igt-text-small {
  font-size: 11px;
  line-height: 16px; }

.igt-text-large {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal; }

/* Weight modifiers
 ========================================================================== */
.igt-text-bold {
  font-weight: bold; }

/* Color modifiers
 ========================================================================== */
.igt-text-muted {
  color: #999 !important; }

.igt-text-primary {
  color: #2d7091 !important; }

.igt-text-success {
  color: #659f13 !important; }

.igt-text-warning {
  color: #e28327 !important; }

.igt-text-danger {
  color: #d85030 !important; }

.igt-text-contrast {
  color: #fff !important;
  text-shadow: 0 2px 2px rgba(68, 68, 68, 0.5); }

/* Alignment modifiers
 ========================================================================== */
.igt-text-left {
  text-align: left !important; }

.igt-text-right {
  text-align: right !important; }

.igt-text-center {
  text-align: center !important; }

.igt-text-justify {
  text-align: justify !important; }

.igt-text-top {
  vertical-align: top !important; }

.igt-text-middle {
  vertical-align: middle !important; }

.igt-text-bottom {
  vertical-align: bottom !important; }

/* Only tablets portrait and smaller */
@media (max-width: 959px) {
  .igt-text-center-medium {
    text-align: center !important; }
  .igt-text-left-medium {
    text-align: left !important; } }

/* Phone landscape and smaller */
@media (max-width: 767px) {
  .igt-text-center-small {
    text-align: center !important; }
  .igt-text-left-small {
    text-align: left !important; } }

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.igt-text-nowrap {
  white-space: nowrap; }

/*
 * Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 */
.igt-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/*
 * Break strings if their length exceeds the width of their container
 */
.igt-text-break {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto; }

/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Container
 ========================================================================== */
.igt-container {
  box-sizing: border-box;
  max-width: 980px;
  padding: 0 25px; }

/* Large screen and bigger */
@media (min-width: 1220px) {
  .igt-container {
    max-width: 1200px;
    padding: 0 35px; } }

/*
 * Micro clearfix
 */
.igt-container:before,
.igt-container:after {
  content: "";
  display: table; }

.igt-container:after {
  clear: both; }

/*
 * Center container
 */
.igt-container-center {
  margin-left: auto;
  margin-right: auto; }

/* Clearing
 ========================================================================== */
/*
 * Micro clearfix
* `table-cell` is used with `:before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * `table` is used again with `:after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
.igt-clearfix:before {
  content: "";
  display: table-cell; }

.igt-clearfix:after {
  content: "";
  display: table;
  clear: both; }

/*
 *  Create a new block formatting context
 */
.igt-nbfc {
  overflow: hidden; }

.igt-nbfc-alt {
  display: table-cell;
  width: 10000px; }

/* Alignment of block elements
 ========================================================================== */
/*
 * Float blocks
 * 1. Prevent content overflow on small devices
 */
.igt-float-left {
  float: left; }

.igt-float-right {
  float: right; }

/* 1 */
[class*='igt-float-'] {
  max-width: 100%; }

/* Alignment of images and objects
 ========================================================================== */
/*
 * Alignment
 */
[class*='igt-align-'] {
  display: block;
  margin-bottom: 15px; }

.igt-align-left {
  margin-right: 15px;
  float: left; }

.igt-align-right {
  margin-left: 15px;
  float: right; }

/* Tablet and bigger */
@media (min-width: 768px) {
  .igt-align-medium-left {
    margin-right: 15px;
    margin-bottom: 15px;
    float: left; }
  .igt-align-medium-right {
    margin-left: 15px;
    margin-bottom: 15px;
    float: right; } }

.igt-align-center {
  margin-left: auto;
  margin-right: auto; }

/* Vertical alignment
 ========================================================================== */
/*
 * Remove whitespace between child elements when using `inline-block`
 */
.igt-vertical-align {
  font-size: 0.001px; }

/*
 *  The `igt-vertical-align` container needs a specific height
 */
.igt-vertical-align:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

/*
 * Sub-object which can have any height
 * 1. Reset whitespace hack
 */
.igt-vertical-align-middle,
.igt-vertical-align-bottom {
  display: inline-block;
  max-width: 100%;
  /* 1 */
  font-size: 1rem; }

.igt-vertical-align-middle {
  vertical-align: middle; }

.igt-vertical-align-bottom {
  vertical-align: bottom; }

/* Height
 ========================================================================== */
/*
 * More robust if padding and border are used
 */
[class*='igt-height'] {
  box-sizing: border-box; }

/*
 * Useful to extend the `html` and `body` element to the full height of the page.
 */
.igt-height-1-1 {
  height: 100%; }

/*
 * Useful to create image teasers
 */
.igt-height-viewport {
  height: 100vh;
  min-height: 600px; }

/* Responsive objects
 * Note: Images are already responsive by default, see Base component
 ========================================================================== */
/*
 * 1. Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.igt-responsive-width,
.igt-responsive-height {
  box-sizing: border-box; }

/*
 * Responsiveness: Sets a maximum width relative to the parent and auto scales the height
 * `important` needed to override `igt-img-preserve img`
 */
.igt-responsive-width {
  max-width: 100% !important;
  height: auto; }

/*
 * Responsiveness: Sets a maximum height relative to the parent and auto scales the width
 * Only works if the parent element has a fixed height.
 */
.igt-responsive-height {
  max-height: 100%;
  width: auto; }

/* Margin
 ========================================================================== */
/*
 * Create a block with the same margin of a paragraph
 * Add margin if adjacent element
 */
.igt-margin {
  margin-bottom: 15px; }

* + .igt-margin {
  margin-top: 15px; }

.igt-margin-top {
  margin-top: 15px !important; }

.igt-margin-bottom {
  margin-bottom: 15px !important; }

.igt-margin-left {
  margin-left: 15px !important; }

.igt-margin-right {
  margin-right: 15px !important; }

/*
 * Larger margins
 */
.igt-margin-large {
  margin-bottom: 50px; }

* + .igt-margin-large {
  margin-top: 50px; }

.igt-margin-large-top {
  margin-top: 50px !important; }

.igt-margin-large-bottom {
  margin-bottom: 50px !important; }

.igt-margin-large-left {
  margin-left: 50px !important; }

.igt-margin-large-right {
  margin-right: 50px !important; }

/*
 * Smaller margins
 */
.igt-margin-small {
  margin-bottom: 5px; }

* + .igt-margin-small {
  margin-top: 5px; }

.igt-margin-small-top {
  margin-top: 5px !important; }

.igt-margin-small-bottom {
  margin-bottom: 5px !important; }

.igt-margin-small-left {
  margin-left: 5px !important; }

.igt-margin-small-right {
  margin-right: 5px !important; }

/*
 * Remove margins
 */
.igt-margin-remove {
  margin: 0 !important; }

.igt-margin-top-remove {
  margin-top: 0 !important; }

.igt-margin-bottom-remove {
  margin-bottom: 0 !important; }

/* Padding
 ========================================================================== */
.igt-padding-remove {
  padding: 0 !important; }

.igt-padding-top-remove {
  padding-top: 0 !important; }

.igt-padding-bottom-remove {
  padding-bottom: 0 !important; }

.igt-padding-vertical-remove {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

/* Border
 ========================================================================== */
.igt-border-circle {
  border-radius: 50%; }

.igt-border-rounded {
  border-radius: 5px; }

/* Headings
 ========================================================================== */
.igt-heading-large {
  font-size: 36px;
  line-height: 42px; }

/* Tablet and bigger */
@media (min-width: 768px) {
  .igt-heading-large {
    font-size: 52px;
    line-height: 64px; } }

/* Link
 ========================================================================== */
/*
 * Let links appear in default text color
 */
.igt-link-muted,
.igt-link-muted a {
  color: #444; }

.igt-link-muted:hover,
.igt-link-muted a:hover {
  color: #444; }

/*
 * Reset link style
 */
.igt-link-reset,
.igt-link-reset a,
.igt-link-reset:hover,
.igt-link-reset a:hover,
.igt-link-reset:focus,
.igt-link-reset a:focus {
  color: inherit;
  text-decoration: none; }

/* Scrollable
 ========================================================================== */
/*
 * Enable scrolling for preformatted text
 */
.igt-scrollable-text {
  height: 300px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  resize: both; }

/*
 * Box with scrolling enabled
 */
.igt-scrollable-box {
  box-sizing: border-box;
  height: 170px;
  padding: 10px;
  border: 1px solid #ddd;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  resize: both; }

.igt-scrollable-box > :last-child {
  margin-bottom: 0; }

/* Overflow
 ========================================================================== */
.igt-overflow-hidden {
  overflow: hidden; }

/*
 * Enable scrollbars if content is clipped
 */
.igt-overflow-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.igt-overflow-container > :last-child {
  margin-bottom: 0; }

/* Position
 ========================================================================== */
.igt-position-absolute,
[class*='igt-position-top'],
[class*='igt-position-bottom'] {
  position: absolute !important; }

.igt-position-top {
  top: 0;
  width: 100%; }

.igt-position-bottom {
  bottom: 0;
  width: 100%; }

.igt-position-top-left {
  top: 0;
  left: 0; }

.igt-position-top-right {
  top: 0;
  right: 0; }

.igt-position-bottom-left {
  bottom: 0;
  left: 0; }

.igt-position-bottom-right {
  bottom: 0;
  right: 0; }

/*
 * Cover
 */
.igt-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

/*
 * Relative
 */
.igt-position-relative {
  position: relative !important; }

/*
 * Z-index
 */
.igt-position-z-index {
  z-index: 1; }

/* Display
 ========================================================================== */
/*
 * Display
 */
.igt-display-block {
  display: block !important; }

.igt-display-inline {
  display: inline !important; }

.igt-display-inline-block {
  display: inline-block !important; }

/*
 * Visibility
 * Avoids setting display to `block` so it works also with `inline-block` and `table`
 */
/* Desktop and bigger */
@media (min-width: 960px) {
  .igt-visible-small {
    display: none !important; }
  .igt-visible-medium {
    display: none !important; }
  .igt-hidden-large {
    display: none !important; } }

/* Tablets portrait */
@media (min-width: 768px) and (max-width: 959px) {
  .igt-visible-small {
    display: none !important; }
  .igt-visible-large {
    display: none !important; }
  .igt-hidden-medium {
    display: none !important; } }

/* Phone landscape and smaller*/
@media (max-width: 767px) {
  .igt-visible-medium {
    display: none !important; }
  .igt-visible-large {
    display: none !important; }
  .igt-hidden-small {
    display: none !important; } }

/* Remove from the flow and screen readers on any device */
.igt-hidden {
  display: none !important;
  visibility: hidden !important; }

/* It's hidden, but still affects layout */
.igt-invisible {
  visibility: hidden !important; }

/* Show on hover */
.igt-visible-hover:hover .igt-hidden,
.igt-visible-hover:hover .igt-invisible {
  display: block !important;
  visibility: visible !important; }

.igt-visible-hover-inline:hover .igt-hidden,
.igt-visible-hover-inline:hover .igt-invisible {
  display: inline-block !important;
  visibility: visible !important; }

/* Hide on touch */
.igt-touch .igt-hidden-touch,
.igt-notouch .igt-hidden-notouch {
  display: none !important; }

/* ========================================================================
   Component: Flex
 ========================================================================== */
.igt-flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex; }

.igt-flex-inline {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: -webkit-inline-box;
  display: inline-flex; }

/*
 * Fixes initial flex-shrink value in IE10
 */
.igt-flex > *,
.igt-flex-inline > * {
  -ms-flex-negative: 1; }

/* Alignment
 ========================================================================== */
/*
 * Vertical alignment
 * Default value is `stretch`
 */
.igt-flex-top {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -webkit-box-align: start;
          align-items: flex-start; }

.igt-flex-middle {
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
          align-items: center; }

.igt-flex-bottom {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -webkit-box-align: end;
          align-items: flex-end; }

/*
 * Horizontal alignment
 * Default value is `flex-start`
 */
.igt-flex-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -webkit-box-pack: center;
          justify-content: center; }

.igt-flex-right {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -webkit-box-pack: end;
          justify-content: flex-end; }

.igt-flex-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -webkit-box-pack: justify;
          justify-content: space-between; }

.igt-flex-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around; }

/* Direction
 ========================================================================== */
.igt-flex-row-reverse {
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse; }

.igt-flex-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column; }

.igt-flex-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
          flex-direction: column-reverse; }

/* Wrap
 ========================================================================== */
.igt-flex-nowrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.igt-flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.igt-flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse; }

/*
 * Horizontal alignment
 * Default value is `stretch`
 */
.igt-flex-wrap-top {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start; }

.igt-flex-wrap-middle {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center; }

.igt-flex-wrap-bottom {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end; }

.igt-flex-wrap-space-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
  align-content: space-between; }

.igt-flex-wrap-space-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
  align-content: space-around; }

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.igt-flex-order-first {
  -ms-flex-order: -1;
  -webkit-order: -1;
  -webkit-box-ordinal-group: 0;
          order: -1; }

.igt-flex-order-last {
  -ms-flex-order: 99;
  -webkit-order: 99;
  -webkit-box-ordinal-group: 100;
          order: 99; }

/* Phone landscape and bigger */
@media (min-width: 480px) {
  .igt-flex-order-first-small {
    -ms-flex-order: -1;
    -webkit-order: -1;
    -webkit-box-ordinal-group: 0;
            order: -1; }
  .igt-flex-order-last-small {
    -ms-flex-order: 99;
    -webkit-order: 99;
    -webkit-box-ordinal-group: 100;
            order: 99; } }

/* Tablet and bigger */
@media (min-width: 768px) {
  .igt-flex-order-first-medium {
    -ms-flex-order: -1;
    -webkit-order: -1;
    -webkit-box-ordinal-group: 0;
            order: -1; }
  .igt-flex-order-last-medium {
    -ms-flex-order: 99;
    -webkit-order: 99;
    -webkit-box-ordinal-group: 100;
            order: 99; } }

/* Desktop and bigger */
@media (min-width: 960px) {
  .igt-flex-order-first-large {
    -ms-flex-order: -1;
    -webkit-order: -1;
    -webkit-box-ordinal-group: 0;
            order: -1; }
  .igt-flex-order-last-large {
    -ms-flex-order: 99;
    -webkit-order: 99;
    -webkit-box-ordinal-group: 100;
            order: 99; } }

/* Large screen and bigger */
@media (min-width: 1220px) {
  .igt-flex-order-first-xlarge {
    -ms-flex-order: -1;
    -webkit-order: -1;
    -webkit-box-ordinal-group: 0;
            order: -1; }
  .igt-flex-order-last-xlarge {
    -ms-flex-order: 99;
    -webkit-order: 99;
    -webkit-box-ordinal-group: 100;
            order: 99; } }

/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.igt-flex-item-none {
  -ms-flex: none;
  -webkit-flex: none;
  -webkit-box-flex: 0;
          flex: none; }

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 * 1. Fixes flex-shrink value in IE10
 */
.igt-flex-item-auto {
  -ms-flex: auto;
  -webkit-flex: auto;
  -webkit-box-flex: 1;
          flex: auto;
  /* 1 */
  -ms-flex-negative: 1; }

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.igt-flex-item-1 {
  -ms-flex: 1;
  -webkit-flex: 1;
  -webkit-box-flex: 1;
          flex: 1; }

/* ========================================================================
   Component: Contrast
 ========================================================================== */
.igt-contrast {
  color: #fff; }
  .igt-contrast a:not([class]),
  .igt-contrast .igt-link {
    color: fade(#fff, 70%);
    text-decoration: none; }
  .igt-contrast a:not([class]):hover,
  .igt-contrast .igt-link:hover {
    color: #fff;
    text-decoration: underline; }
  .igt-contrast :not(pre) > code,
  .igt-contrast :not(pre) > kbd,
  .igt-contrast :not(pre) > samp {
    color: #fff; }
  .igt-contrast em {
    color: #fff; }
  .igt-contrast h1, .igt-contrast h2, .igt-contrast h3, .igt-contrast h4, .igt-contrast h5, .igt-contrast h6 {
    color: #fff; }
  .igt-contrast hr {
    border-top-color: fade(#fff, 20%); }
  .igt-contrast .igt-nav li > a,
  .igt-contrast .igt-nav li > a:hover {
    text-decoration: none; }
  .igt-contrast .igt-nav-side > li > a {
    color: #fff; }
  .igt-contrast .igt-nav-side > li > a:hover,
  .igt-contrast .igt-nav-side > li > a:focus {
    background: fade(#fff, 10%);
    color: #fff; }
  .igt-contrast .igt-nav-side > li.igt-active > a {
    background: #fff;
    color: #444; }
  .igt-contrast .igt-nav-side .igt-nav-header {
    color: #fff; }
  .igt-contrast .igt-nav-side .igt-nav-divider {
    border-top-color: fade(#fff, 20%); }
  .igt-contrast .igt-nav-side ul a {
    color: fade(#fff, 70%); }
  .igt-contrast .igt-nav-side ul a:hover {
    color: #fff; }
  .igt-contrast .igt-subnav > * > a {
    color: fade(#fff, 70%);
    text-decoration: none; }
  .igt-contrast .igt-subnav > * > a:hover,
  .igt-contrast .igt-subnav > * > a:focus {
    color: #fff;
    text-decoration: none; }
  .igt-contrast .igt-subnav > .igt-active > a {
    color: #fff; }
  .igt-contrast .igt-subnav-line > :nth-child(n+2):before {
    border-left-color: fade(#fff, 20%); }
  .igt-contrast .igt-subnav-pill > * > a:hover,
  .igt-contrast .igt-subnav-pill > * > a:focus {
    background: fade(#fff, 70%);
    color: #444;
    text-decoration: none; }
  .igt-contrast .igt-subnav-pill > .igt-active > a {
    background: #fff;
    color: #444; }
  .igt-contrast .igt-list-line > li:nth-child(n+2) {
    border-top-color: fade(#fff, 20%); }
  .igt-contrast .igt-form select,
  .igt-contrast .igt-form textarea,
  .igt-contrast .igt-form input:not([type]),
  .igt-contrast .igt-form input[type="text"],
  .igt-contrast .igt-form input[type="password"],
  .igt-contrast .igt-form input[type="datetime"],
  .igt-contrast .igt-form input[type="datetime-local"],
  .igt-contrast .igt-form input[type="date"],
  .igt-contrast .igt-form input[type="month"],
  .igt-contrast .igt-form input[type="time"],
  .igt-contrast .igt-form input[type="week"],
  .igt-contrast .igt-form input[type="number"],
  .igt-contrast .igt-form input[type="email"],
  .igt-contrast .igt-form input[type="url"],
  .igt-contrast .igt-form input[type="search"],
  .igt-contrast .igt-form input[type="tel"],
  .igt-contrast .igt-form input[type="color"] {
    border-color: fade(#fff, 80%);
    background: fade(#fff, 80%);
    color: #444;
    background-clip: padding-box; }
    .igt-contrast .igt-form select:focus,
    .igt-contrast .igt-form textarea:focus,
    .igt-contrast .igt-form input:not([type]):focus,
    .igt-contrast .igt-form input[type="text"]:focus,
    .igt-contrast .igt-form input[type="password"]:focus,
    .igt-contrast .igt-form input[type="datetime"]:focus,
    .igt-contrast .igt-form input[type="datetime-local"]:focus,
    .igt-contrast .igt-form input[type="date"]:focus,
    .igt-contrast .igt-form input[type="month"]:focus,
    .igt-contrast .igt-form input[type="time"]:focus,
    .igt-contrast .igt-form input[type="week"]:focus,
    .igt-contrast .igt-form input[type="number"]:focus,
    .igt-contrast .igt-form input[type="email"]:focus,
    .igt-contrast .igt-form input[type="url"]:focus,
    .igt-contrast .igt-form input[type="search"]:focus,
    .igt-contrast .igt-form input[type="tel"]:focus,
    .igt-contrast .igt-form input[type="color"]:focus {
      border-color: #fff;
      background: #fff;
      color: #444; }
  .igt-contrast .igt-form :-ms-input-placeholder {
    color: fade(#444, 70%) !important; }
  .igt-contrast .igt-form ::-moz-placeholder {
    color: fade(#444, 70%); }
  .igt-contrast .igt-form ::-webkit-input-placeholder {
    color: fade(#444, 70%); }
  .igt-contrast .igt-button {
    color: #444;
    background: #fff; }
  .igt-contrast .igt-button:hover,
  .igt-contrast .igt-button:focus {
    background-color: fade(#fff, 80%);
    color: #444; }
  .igt-contrast .igt-button:active,
  .igt-contrast .igt-button.igt-active {
    background-color: fade(#fff, 70%);
    color: #444; }
  .igt-contrast .igt-button-primary {
    background-color: #39a448;
    color: #fff; }
  .igt-contrast .igt-button-primary:hover,
  .igt-contrast .igt-button-primary:focus {
    background-color: #4ec25f;
    color: #fff; }
  .igt-contrast .igt-button-primary:active,
  .igt-contrast .igt-button-primary.igt-active {
    background-color: #2c7e37;
    color: #fff; }
  .igt-contrast .igt-icon-hover {
    color: fade(#fff, 70%); }
  .igt-contrast .igt-icon-hover:hover {
    color: #fff; }
  .igt-contrast .igt-icon-button {
    background: #fff;
    color: #444; }
  .igt-contrast .igt-icon-button:hover,
  .igt-contrast .igt-icon-button:focus {
    background-color: fade(#fff, 80%);
    color: #444; }
  .igt-contrast .igt-icon-button:active {
    background-color: fade(#fff, 70%);
    color: #444; }
  .igt-contrast .igt-text-muted {
    color: fade(#fff, 60%) !important; }
  .igt-contrast .igt-text-primary {
    color: #2d7091 !important; }

/* ========================================================================
   Component: Print
 ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/* ========================================================================
   Component: Accordion
 ========================================================================== */
/* Sub-object: `igt-accordion-title`
 ========================================================================== */
.igt-accordion-title {
  margin-top: 0;
  margin-bottom: 15px;
  padding: 5px 15px;
  background: #fff;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer; }
  .igt-accordion-title:before {
    content: "";
    font-family: FontAwesome;
    vertical-align: middle;
    position: relative;
    padding-right: 15px; }

.igt-accordion-title.igt-active {
  color: #39a448; }
  .igt-accordion-title.igt-active:before {
    color: #39a448;
    content: ""; }

/* Sub-object: `igt-accordion-content`
 ========================================================================== */
.igt-accordion-content {
  padding: 0 15px 15px 45px;
  margin-bottom: 15px; }

/*
 * Micro clearfix to make panels more robust
 */
.igt-accordion-content:before,
.igt-accordion-content:after {
  content: "";
  display: table; }

.igt-accordion-content:after {
  clear: both; }

/*
 * Remove margin from the last-child
 */
.igt-accordion-content > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Dotnav
 ========================================================================== */
/*
 * 1. Gutter
 * 2. Remove default list style
 */
.igt-dotnav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* 1 */
  margin-left: -15px;
  margin-top: -15px;
  /* 2 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions
 * 2. Horizontal gutter is using `padding` so `igt-width-*` classes can be applied
 */
.igt-dotnav > * {
  /* 1 */
  -ms-flex: none;
  -webkit-flex: none;
  -webkit-box-flex: 0;
          flex: none;
  /* 2 */
  padding-left: 15px;
  margin-top: 15px; }

/*
 * DEPRECATED IE9 Support
 */
.igt-dotnav:before,
.igt-dotnav:after {
  content: "";
  display: block;
  overflow: hidden; }

.igt-dotnav:after {
  clear: both; }

.igt-dotnav > * {
  float: left; }

/* Items
 ========================================================================== */
/*
 * Items
 * 1. Hide text if present
 */
.igt-dotnav > * > * {
  display: block;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(146, 154, 160, 0.1);
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.igt-dotnav > * > :hover,
.igt-dotnav > * > :focus {
  background: rgba(146, 154, 160, 0.4);
  /* 2 */
  outline: none; }

/* OnClick */
.igt-dotnav > * > :active {
  background: rgba(146, 154, 160, 0.6); }

/* Active */
.igt-dotnav > .igt-active > * {
  background: rgba(146, 154, 160, 0.4); }

/* Modifier: `igt-dotnav-contrast`
 ========================================================================== */
.igt-dotnav-contrast > * > * {
  background: rgba(255, 255, 255, 0.4); }

/*
 * Hover
 * 1. Apply hover style also to focus state
 */
.igt-dotnav-contrast > * > :hover,
.igt-dotnav-contrast > * > :focus {
  background: rgba(255, 255, 255, 0.7); }

/* OnClick */
.igt-dotnav-contrast > * > :active {
  background: rgba(255, 255, 255, 0.9); }

/* Active */
.igt-dotnav-contrast > .igt-active > * {
  background: rgba(255, 255, 255, 0.9); }

/* Modifier: 'igt-dotnav-vertical'
 ========================================================================== */
/*
 * DEPRECATED
 */
.igt-dotnav-vertical {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column; }

/*
 * DEPRECATED IE9 Support
 */
.igt-dotnav-vertical > * {
  float: none; }

/* ========================================================================
   Component: Form advanced
   Note: Only works in Webkit at the moment
 ========================================================================== */
/*
 * 1. Style
 * 2. Makes box more robust so it clips the child element
 * 3. Vertical alignment
 * 4. Remove default style
 * 5. Fix black background on iOS
 */
.igt-form input[type="radio"],
.igt-form input[type="checkbox"] {
  /* 1 */
  display: inline-block;
  height: 14px;
  width: 14px;
  border: 1px solid #aaa;
  /* 2 */
  overflow: hidden;
  /* 3 */
  margin-top: -4px;
  vertical-align: middle;
  /* 4 */
  -webkit-appearance: none;
  outline: 0;
  /* 5 */
  background: transparent; }

/* Radio */
.igt-form input[type="radio"] {
  border-radius: 50%; }

/*
 * Checked
 */
.igt-form input[type=radio]:before,
.igt-form input[type=checkbox]:before {
  display: block; }

/* Radio */
.igt-form input[type=radio]:checked:before {
  content: '';
  width: 8px;
  height: 8px;
  margin: 2px auto 0;
  border-radius: 50%;
  background: #39a448; }

/* Checkbox */
.igt-form input[type=checkbox]:checked:before,
.igt-form input[type=checkbox]:indeterminate:before {
  content: "";
  font-family: FontAwesome;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  line-height: 12px;
  color: #39a448; }

.igt-form input[type=checkbox]:indeterminate:before {
  content: ""; }

/*
 * Disabled
 */
.igt-form input[type=radio]:disabled,
.igt-form input[type=checkbox]:disabled {
  border-color: #ddd; }

.igt-form input[type=radio]:disabled:checked:before {
  background-color: #aaa; }

.igt-form input[type=checkbox]:disabled:checked:before,
.igt-form input[type=checkbox]:disabled:indeterminate:before {
  color: #aaa; }

/* ========================================================================
   Component: Form select
 ========================================================================== */
/*
 * 1. Behave like form elements
 * 2. Create position context for dropdowns
 * 3. Clip content
 */
.igt-form-select {
  /* 1 */
  display: inline-block;
  vertical-align: middle;
  /* 2 */
  position: relative;
  /* 3 */
  overflow: hidden; }

/*
 * 1. Required for Firefox
 * 1. Required for Webkit to make `height` work
 */
.igt-form-select select {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  /* 1 */
  left: 0;
  /* 2 */
  -webkit-appearance: none; }

/* ========================================================================
   Component: Notify
 ========================================================================== */
/*
 * Message container for positioning
 */
.igt-notify {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  box-sizing: border-box;
  width: 350px; }

/* Position modifiers
========================================================================== */
.igt-notify-top-right,
.igt-notify-bottom-right {
  left: auto;
  right: 10px; }

.igt-notify-top-center,
.igt-notify-bottom-center {
  left: 50%;
  margin-left: -175px; }

.igt-notify-bottom-left,
.igt-notify-bottom-right,
.igt-notify-bottom-center {
  top: auto;
  bottom: 10px; }

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 479px) {
  /*
     * Fit in small screen
     */
  .igt-notify {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0; } }

/* Sub-object: `igt-notify-message`
========================================================================== */
.igt-notify-message {
  position: relative;
  margin-bottom: 10px;
  padding: 15px;
  background: #fff;
  color: #444;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(68, 68, 68, 0.1); }

/* Close in notify
 ========================================================================== */
.igt-notify-message > .igt-close {
  visibility: hidden;
  float: right; }

.igt-notify-message:hover > .igt-close {
  visibility: visible; }

/* Modifier: `igt-alert-info`
 ========================================================================== */
.igt-notify-message-primary {
  background: #d9edf7;
  color: #31708f; }

/* Modifier: `igt-alert-success`
 ========================================================================== */
.igt-notify-message-success {
  background: #dff0d8;
  color: #3c763d; }

/* Modifier: `igt-notify-message-warning`
 ========================================================================== */
.igt-notify-message-warning {
  background: #fcf8e3;
  color: #8a6d3b; }

/* Modifier: `igt-notify-message-danger`
 ========================================================================== */
.igt-notify-message-danger {
  background: #f2dede;
  color: #a94442; }

/* ========================================================================
   Component: Progress
 ========================================================================== */
/*
 * 1. Clearing
 * 2. Vertical alignment if text is used
 */
.igt-progress {
  box-sizing: border-box;
  height: 20px;
  margin-bottom: 15px;
  background: #eee;
  /* 1 */
  overflow: hidden;
  /* 2 */
  line-height: 20px; }

/*
 * Add margin if adjacent element
 */
* + .igt-progress {
  margin-top: 15px; }

/* Sub-object: `igt-progress-bar`
 ========================================================================== */
/*
 * 1. Transition
 * 2. Needed for text
 */
.igt-progress-bar {
  width: 0;
  height: 100%;
  background: #39a448;
  float: left;
  /* 1 */
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  /* 2 */
  font-size: 12px;
  color: #fff;
  text-align: center; }

/* Size modifiers
 ========================================================================== */
/* Mini */
.igt-progress-mini {
  height: 6px; }

/* Small */
.igt-progress-small {
  height: 12px; }

/* Color modifiers
 ========================================================================== */
.igt-progress-success .igt-progress-bar {
  background-color: #8cc14c; }

.igt-progress-warning .igt-progress-bar {
  background-color: #faa732; }

.igt-progress-danger .igt-progress-bar {
  background-color: #da314b; }

/* Modifier: `igt-progress-striped`
 ========================================================================== */
.igt-progress-striped .igt-progress-bar {
  background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 30px 30px; }

/*
 * Animation
 */
.igt-progress-striped.igt-active .igt-progress-bar {
  -webkit-animation: igt-progress-bar-stripes 2s linear infinite;
  animation: igt-progress-bar-stripes 2s linear infinite; }

@-webkit-keyframes igt-progress-bar-stripes {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 30px 0; } }

@keyframes igt-progress-bar-stripes {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 30px 0; } }

/* ========================================================================
   Component: Search
 ========================================================================== */
/*
 * 1. Create position context for dropdowns
 * 2. Needed for `form` element
 */
.igt-search {
  display: inline-block;
  /* 1 */
  position: relative;
  /* 2 */
  margin: 0; }

/*
 * Icon
 */
.igt-search:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  line-height: 30px;
  text-align: center;
  font-family: FontAwesome;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.2); }

/* Sub-object `igt-search-field`
 ========================================================================== */
/*
 * Removes inner padding and border in Firefox 4+.
 */
.igt-search-field::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * Remove inner padding and search cancel button in Chrome, Safari and Opera on OS X.
 */
.igt-search-field::-webkit-search-cancel-button,
.igt-search-field::-webkit-search-decoration {
  -webkit-appearance: none; }

/*
 * Removes cancel button in IE10
 */
.igt-search-field::-ms-clear {
  display: none; }

/*
 * Removes placeholder transparency in Firefox.
 */
.igt-search-field::-moz-placeholder {
  opacity: 1; }

/*
 * 1. Define consistent box sizing.
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Correct `font` properties and `color` not being inherited.
 * 5. Remove default style in iOS.
 * 6. Style
 */
.igt-search-field {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  color: #444;
  /* 5 */
  -webkit-appearance: none;
  /* 6 */
  width: 120px;
  height: 30px;
  padding: 0 0 0 30px;
  border: 1px solid transparent;
  background: transparent;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  vertical-align: middle; }

/* Placeholder */
.igt-search-field:-ms-input-placeholder {
  color: #999 !important; }

.igt-search-field::-moz-placeholder {
  color: #999; }

.igt-search-field::-webkit-input-placeholder {
  color: #999; }

/* Focus */
.igt-search-field:focus {
  outline: 0; }

/* Focus + Active */
.igt-search-field:focus,
.igt-search.igt-active .igt-search-field {
  width: 180px; }

/* Dropdown modifier: `igt-dropdown-search`
 ========================================================================== */
.igt-dropdown-search {
  width: 300px;
  margin-top: 0;
  background: #f5f5f5;
  color: #444; }

.igt-open > .igt-dropdown-search {
  -webkit-animation: igt-slide-top-fixed 0.2s ease-in-out;
  animation: igt-slide-top-fixed 0.2s ease-in-out; }

/*
 * Dependency `igt-navbar-flip`
 */
.igt-navbar-flip .igt-dropdown-search {
  margin-top: 5px;
  margin-right: -15px; }

/* Nav modifier `igt-nav-search`
 ========================================================================== */
/*
 * Items
 */
.igt-nav-search > li > a {
  color: #444; }

/*
 * Active
 * 1. Remove default focus style
 */
.igt-nav-search > li.igt-active > a {
  background: #39a448;
  color: #fff;
  /* 1 */
  outline: none; }

/*
 * Sub-object: `igt-nav-header`
 */
.igt-nav-search .igt-nav-header {
  color: #999; }

/*
 * Sub-object: `igt-nav-divider`
 */
.igt-nav-search .igt-nav-divider {
  border-top: 1px solid #ddd; }

/*
 * Nested items
 */
.igt-nav-search ul a {
  color: #07D; }

.igt-nav-search ul a:hover {
  color: #059; }

/* Search in offcanvas
 ========================================================================== */
.igt-offcanvas .igt-search {
  display: block;
  margin: 20px 15px; }

.igt-offcanvas .igt-search:before {
  color: #777; }

.igt-offcanvas .igt-search-field {
  width: 100%;
  border-color: transparent;
  background: #1a1a1a;
  color: #ccc; }

.igt-offcanvas .igt-search-field:-ms-input-placeholder {
  color: #777 !important; }

.igt-offcanvas .igt-search-field::-moz-placeholder {
  color: #777; }

.igt-offcanvas .igt-search-field::-webkit-input-placeholder {
  color: #777; }

/* ========================================================================
   Component: Slidenav
 ========================================================================== */
/*
 * 1. Required for `a` elements
 * 2. Dimension
 * 3. Style
 */
.igt-slidenav {
  /* 1 */
  display: inline-block;
  /* 2 */
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  /* 3 */
  line-height: 60px;
  color: rgba(50, 50, 50, 0.4);
  font-size: 60px;
  text-align: center; }

/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 * 3. Required for `a` elements
 * 4. Style
 */
.igt-slidenav:hover,
.igt-slidenav:focus {
  /* 2 */
  outline: none;
  /* 3 */
  text-decoration: none;
  /* 4 */
  color: rgba(50, 50, 50, 0.7);
  cursor: pointer; }

/* Active */
.igt-slidenav:active {
  color: rgba(50, 50, 50, 0.9); }

/*
 * Icons
 */
.igt-slidenav-previous:before {
  content: "";
  font-family: FontAwesome; }

.igt-slidenav-next:before {
  content: "";
  font-family: FontAwesome; }

/* Sub-object: `igt-slidenav-position`
 ========================================================================== */
/*
 * Create position context
 */
.igt-slidenav-position {
  position: relative; }

/*
 * Center vertically
 */
.igt-slidenav-position .igt-slidenav {
  display: none;
  position: absolute;
  top: 50%;
  z-index: 1;
  margin-top: -30px; }

.igt-slidenav-position:hover .igt-slidenav {
  display: block; }

.igt-slidenav-position .igt-slidenav-previous {
  left: 20px; }

.igt-slidenav-position .igt-slidenav-next {
  right: 20px; }

/* Modifier: `igt-slidenav-contrast`
 ========================================================================== */
.igt-slidenav-contrast {
  color: rgba(255, 255, 255, 0.5); }

/*
 * Hover
 * 1. Apply hover style also to focus state
 */
.igt-slidenav-contrast:hover,
.igt-slidenav-contrast:focus {
  color: rgba(255, 255, 255, 0.7); }

/* Active */
.igt-slidenav-contrast:active {
  color: rgba(255, 255, 255, 0.9); }

/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Create position context
 * 2. Create stacking context to prevent z-index issues with other components
 * 3. Deactivate browser history navigation in IE11
 */
.igt-slider {
  /* 1 */
  position: relative;
  /* 2 */
  z-index: 0;
  /* 3 */
  -ms-touch-action: pan-y;
      touch-action: pan-y; }

/*
 * 1. Reset list style without interfering with grid
 */
.igt-slider:not(.igt-grid) {
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * Sub-object item
 * 1. Position items above each other
 */
.igt-slider > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0; }

/*
 * Clip child elements
 */
.igt-slider-container {
  overflow: hidden; }

/*
 * Dragged
 */
.igt-slider:not(.igt-drag) {
  -webkit-transition: -webkit-transform 200ms linear;
  transition: transform 200ms linear; }

/*
 * 1. Makes text unselectable
 */
.igt-slider.igt-drag {
  cursor: col-resize;
  /* 1 */
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/*
 * 1. Prevents images and links from being dragged (default browser behavior)
 * 2. Disables the default callout shown when you touch and hold a touch target
 * Currently only works in Webkit
 */
.igt-slider a,
.igt-slider img {
  /* 1 */
  -webkit-user-drag: none;
  user-drag: none;
  /* 2 */
  -webkit-touch-callout: none; }

/*
 * 1. Prevents images and links from being dragged in Firefox
 */
.igt-slider img {
  pointer-events: none; }

/* Modifier: `igt-slider-fullscreen`
 ========================================================================== */
.igt-slider-fullscreen,
.igt-slider-fullscreen > li {
  height: 100vh; }

/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Create position context
 * 2. Create stacking context to prevent z-index issues with other components
 * 3. Set width because child elements are positioned absolute. Height is set via JS
 * 4. Reset list style
 * 5. Clip child elements
 * 6. Deactivate browser history navigation in IE11
 */
.igt-slideshow {
  /* 1 */
  position: relative;
  /* 2 */
  z-index: 0;
  /* 3 */
  width: 100%;
  /* 4 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 5 */
  overflow: hidden;
  /* 6 */
  -ms-touch-action: pan-y;
      touch-action: pan-y; }

/*
 * Sub-object item
 * 1. Position items above each other
 * 2. Expand to parent container width
 * 3. Hide by default
 */
.igt-slideshow > li {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  opacity: 0; }

/*
 * Active
 * 1. Stack at first
 * 2. Show slide
 */
.igt-slideshow > .igt-active {
  /* 1 */
  z-index: 10;
  /* 2 */
  opacity: 1; }

/*
 * Hide default images which is only relevant to keep existing proportions
 */
.igt-slideshow > li > img {
  visibility: hidden; }

/*
 * Pointer for controls
 */
[data-igt-slideshow-slide] {
  cursor: pointer; }

/* Modifier: `igt-slideshow-fullscreen`
 ========================================================================== */
.igt-slideshow-fullscreen,
.igt-slideshow-fullscreen > li {
  height: 100vh; }

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.igt-slideshow-fade-out {
  -webkit-animation: igt-fade 0.5s linear reverse;
  animation: igt-fade 0.5s linear reverse; }

/*
 * Scroll
 */
.igt-slideshow-scroll-forward-in {
  -webkit-animation: igt-slide-right 0.5s ease-in-out;
  animation: igt-slide-right 0.5s ease-in-out; }

.igt-slideshow-scroll-forward-out {
  -webkit-animation: igt-slide-left 0.5s ease-in-out reverse;
  animation: igt-slide-left 0.5s ease-in-out reverse; }

.igt-slideshow-scroll-backward-in {
  -webkit-animation: igt-slide-left 0.5s ease-in-out;
  animation: igt-slide-left 0.5s ease-in-out; }

.igt-slideshow-scroll-backward-out {
  -webkit-animation: igt-slide-right 0.5s ease-in-out reverse;
  animation: igt-slide-right 0.5s ease-in-out reverse; }

/*
 * Scale
 */
.igt-slideshow-scale-out {
  -webkit-animation: igt-fade-scale-15 0.5s ease-in-out reverse;
  animation: igt-fade-scale-15 0.5s ease-in-out reverse; }

/*
 * Swipe
 */
.igt-slideshow-swipe-forward-in {
  -webkit-animation: igt-slide-left-33 0.5s ease-in-out;
  animation: igt-slide-left-33 0.5s ease-in-out; }

.igt-slideshow-swipe-forward-out {
  -webkit-animation: igt-slide-left 0.5s ease-in-out reverse;
  animation: igt-slide-left 0.5s ease-in-out reverse; }

.igt-slideshow-swipe-backward-in {
  -webkit-animation: igt-slide-right-33 0.5s ease-in-out;
  animation: igt-slide-right-33 0.5s ease-in-out; }

.igt-slideshow-swipe-backward-out {
  -webkit-animation: igt-slide-right 0.5s ease-in-out reverse;
  animation: igt-slide-right 0.5s ease-in-out reverse; }

.igt-slideshow-swipe-forward-in:before,
.igt-slideshow-swipe-backward-in:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  -webkit-animation: igt-fade 0.5s ease-in-out reverse;
  animation: igt-fade 0.5s ease-in-out reverse; }

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set fixed position
 * 3. Set dimensions
 * 4. Set style
 */
.igt-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  /* 3 */
  box-sizing: border-box;
  max-width: 200px;
  padding: 5px 8px;
  /* 4 */
  background: #333;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  border-radius: 3px; }

/* Triangle
 ========================================================================== */
/*
 * 1. Dashed is less antialised than solid
 */
.igt-tooltip:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  /* 1 */
  border: 5px dashed #333; }

/* Direction modifiers
 ========================================================================== */
/*
 * Top
 */
.igt-tooltip-top:after,
.igt-tooltip-top-left:after,
.igt-tooltip-top-right:after {
  bottom: -5px;
  border-top-style: solid;
  border-bottom: none;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: #333; }

/*
 * Bottom
 */
.igt-tooltip-bottom:after,
.igt-tooltip-bottom-left:after,
.igt-tooltip-bottom-right:after {
  top: -5px;
  border-bottom-style: solid;
  border-top: none;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #333; }

/*
 * Top/Bottom center
 */
.igt-tooltip-top:after,
.igt-tooltip-bottom:after {
  left: 50%;
  margin-left: -5px; }

/*
 * Top/Bottom left
 */
.igt-tooltip-top-left:after,
.igt-tooltip-bottom-left:after {
  left: 10px; }

/*
 * Top/Bottom right
 */
.igt-tooltip-top-right:after,
.igt-tooltip-bottom-right:after {
  right: 10px; }

/*
 * Left
 */
.igt-tooltip-left:after {
  right: -5px;
  top: 50%;
  margin-top: -5px;
  border-left-style: solid;
  border-right: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #333; }

/*
 * Right
 */
.igt-tooltip-right:after {
  left: -5px;
  top: 50%;
  margin-top: -5px;
  border-right-style: solid;
  border-left: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: #333; }

.igt-navbar-brand {
  background-image: url(../images/logotype.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 12rem;
  width: 12rem;
  height: 4rem; }
  .svg .igt-navbar-brand {
    background-image: url(../images/logotype.svg); }
  .igt-navbar-brand h1 {
    text-indent: -9999px;
    visibility: hidden;
    font-size: 0;
    line-height: 1;
    display: none;
    visibility: hidden; }
  .igt-navbar-brand span {
    float: right;
    line-height: 4.6rem;
    height: 4rem;
    font-family: "Merriweather", Georgia, serif;
    font-weight: bold;
    color: #39a448;
    font-size: 1.5rem; }

.igt-navbar-trips .igt-navbar-brand {
  width: 17.5rem; }

.igt-navbar-trips-pro .igt-navbar-brand {
  width: 20.5rem; }

@media (max-width: 959px) {
  .igt-navbar-brand {
    height: 3.5rem; } }

.igt-navbar-nav > li > a {
  height: 3rem;
  line-height: 3rem; }

.igt-navbar-top .igt-navbar-flip {
  margin-top: 12px; }

.igt-navbar-top .igt-button {
  line-height: 40px;
  height: 40px; }

.igt-navbar-top .igt-navbar-nav > li {
  margin-left: 1rem; }

.igt-navbar-top .igt-button-primary {
  color: #fff;
  box-shadow: none;
  padding-right: 40px; }
  .igt-navbar-top .igt-button-primary:after {
    right: 15px; }
  .igt-navbar-top .igt-button-primary:hover {
    background-color: #4ec25f;
    color: #fff; }
  .igt-navbar-top .igt-button-primary:focus {
    background-color: #2c7e37;
    color: #fff; }

.igt-navbar-top .igt-form .igt-button {
  background-color: #fff; }
  .igt-navbar-top .igt-form .igt-button:hover {
    background-color: #f5f5f5; }

.igt-navbar-top .igt-form .igt-button-primary {
  background: #39a448; }
  .igt-navbar-top .igt-form .igt-button-primary:hover {
    background-color: #4ec25f;
    color: #fff; }
  .igt-navbar-top .igt-form .igt-button-primary:focus {
    background-color: #2c7e37;
    color: #fff; }

.igt-button-dropdown .igt-button {
  background-color: #fff; }
  .igt-button-dropdown .igt-button:hover {
    background-color: #f5f5f5; }

.igt-button-dropdown.igt-open .igt-button {
  background-color: #f5f5f5; }

.igt-navbar-top .igt-form input {
  width: 360px; }

.igt-navbar-trips .igt-form input {
  width: 160px; }

.igt-navbar-top .igt-navbar-toggle {
  display: block; }

@media (max-width: 959px) {
  .igt-navbar-top .igt-form input {
    width: 240px; }
  .igt-navbar-trips .igt-form input {
    width: 160px; } }

@media (min-width: 959px) {
  .igt-navbar-top .igt-navbar-toggle {
    display: none !important; } }

.igt-offcanvas .igt-form {
  margin: 15px 0 0 15px; }

.igt-offcanvas-trips .igt-form {
  display: block;
  margin: 15px; }

.igt-offcanvas-trips input {
  width: 100%; }

.section-hero {
  height: 38rem;
  border-width: 2px 0;
  border-color: rgba(68, 68, 68, 0.33);
  border-style: solid; }
  .section-hero .igt-text-contrast {
    font-family: "Merriweather", Georgia, serif; }
  .section-hero .igt-form legend:after {
    border: 0; }
  .section-hero .igt-form input {
    width: 100%;
    height: 48px;
    padding: 10px 20px; }
  .section-hero h4 {
    margin: 2rem 0; }
  .section-hero a {
    padding-left: .25rem; }
  .section-hero .igt-button-round {
    position: absolute;
    right: 2rem;
    bottom: -2rem;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    padding: 0;
    margin: 0;
    z-index: 50;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 2px rgba(68, 68, 68, 0.5);
    font-size: 1.5rem; }
    .section-hero .igt-button-round:after {
      font-family: FontAwesome;
      vertical-align: middle;
      content: "\f175"; }

@media (min-width: 959px) {
  .section-hero {
    height: 32rem; }
    .section-hero .igt-panel {
      width: 66.6666666667%; }
    .section-hero a, .section-hero span {
      float: right; }
    .section-hero .igt-button-round {
      right: 0; } }

.section-intro-primary i {
  color: #39a448;
  font-size: 2.5rem; }

.section-intro-secondary {
  height: 44rem; }

@media (min-width: 767px) {
  .section-intro-secondary {
    height: 24rem; } }

@media (min-width: 959px) {
  .section-intro-secondary {
    height: 18rem; } }

.section-testimonial .igt-active {
  position: relative !important; }

.section-pricing .igt-panel-box {
  padding: 0; }

.section-pricing .igt-list li {
  padding: 1rem 1.5rem;
  margin: 0;
  min-height: 5rem; }

.section-pricing .igt-list li:nth-child(2) span {
  text-align: center;
  clear: both;
  display: block;
  font-family: "Merriweather", Georgia, serif;
  font-style: italic;
  margin-bottom: 1rem; }

.section-pricing .igt-panel-box:hover .igt-list li:nth-child(2) {
  background: rgba(57, 164, 72, 0.1); }

.section-contact {
  height: 52rem; }
  .section-contact input,
  .section-contact textarea {
    border: 0 !important;
    width: 100%; }
  .section-contact textarea {
    resize: none; }
  .section-contact .igt-text-contrast a {
    color: #fff;
    text-decoration: underline; }
    .section-contact .igt-text-contrast a:hover {
      color: #fff;
      text-decoration: none; }
  .section-contact .links-social a {
    font-size: 1.5rem;
    padding: 0 1rem; }

@media (min-width: 959px) {
  .section-contact {
    height: 34rem; } }

.section-intro-trips .igt-panel > p {
  margin-top: 0; }

.section-intro-trips .igt-clearfix p {
  margin-top: 0;
  margin-bottom: 1rem; }

.section-intro-trips .igt-align-left {
  margin-bottom: 3.5rem; }

.section-intro-trips .igt-panel-box-primary:hover {
  border-color: #eee; }

.igt-slideshow-trips .igt-slideshow {
  border: 5px solid #fff;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(68, 68, 68, 0.2); }

.igt-slideshow-trips .igt-dotnav {
  margin-top: 0; }

.section-page-header h3 {
  margin-bottom: 0; }

.igt-page-regular {
  border-top: 2px solid #eee; }

.section-page-header h2,
.section-page-header * + h2 {
  margin-bottom: 0;
  margin-top: 0; }

.igt-pricing-table td {
  min-width: 14rem;
  max-width: 20rem;
  padding: 1rem 0.5rem; }
  .igt-pricing-table td:first-child,
  .igt-pricing-table td:nth-child(2n) {
    border-right: 1px solid #eee; }

.igt-pricing-table td.limited-access {
  background: #fcf8e3;
  color: #8a6d3b; }

.igt-pricing-table td.full-access {
  background: #dff0d8;
  color: #3c763d; }

.igt-share-buttons a {
  font-size: 1.25rem;
  float: left;
  clear: right;
  padding: .5rem;
  margin-right: .5rem;
  display: block; }

.footer span {
  display: block; }

.footer .igt-nav > li > a {
  padding-left: 0; }

.footer .logo-footer {
  background-image: url(../images/logotype-footer.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 100%;
  width: 12rem;
  height: 4rem; }
  .svg .footer .logo-footer {
    background-image: url(../images/logotype-footer.svg); }

.footer a {
  color: #444; }
  .footer a:hover {
    text-decoration: underline; }

.footer input {
  width: 100% !important; }
